<div class=" card-historiques  bg-white p-4" [ngClass]="{'cover': !allowed_to_update}"*ngIf="histAccess" >
  <div class="card-doc-title ml-3 row" >
<i class="fa-solid fa-clock-rotate-left mr-2 fa-lg mt-2"></i>
     {{"languages.opportunite.details.actionHistorique" | translate }}
  </div>
  <mat-form-field class="w-100 p-3">
    <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'languages.miniListing.search' | translate}}" #input>
  </mat-form-field>
  <div class="mat-elevation-z8" [ngClass]="{'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar'}">
    <table mat-table class="w-100" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > {{"languages.opportunite.details.actionDate" | translate }}</th>
        <td mat-cell *matCellDef="let row" > {{row.date}} </td>
      </ng-container>
      <ng-container matColumnDef="commentaire">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > {{"languages.opportunite.details.standardComment" | translate }}</th>
        <td mat-cell *matCellDef="let row" > {{row.commentaire}} </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > {{"languages.opportunite.label_user" | translate }}</th>
        <td mat-cell *matCellDef="let row" > {{row.mail}} </td>
      </ng-container>
      <ng-container matColumnDef="commentaireD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > {{"languages.opportunite.details.commentaireDetaille" | translate }}</th>
        <td mat-cell *matCellDef="let row" > {{row.comm_opp}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="7"><div class="col-12 mt-2 d-flex justify-content-center">
          <img src="assets/icons/nodata.svg">

        </div>
        <div class="col-12  d-flex justify-content-center">
          <p class="noData">{{"languages.affaire.noDataAvailable" | translate }}</p>
        </div> </td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
<hr>
<div>
  <app-historique-communication *ngIf="histCommAccess"></app-historique-communication>
</div>
