

            <div class="dropdown">

              <button class="btn-save" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" [attr.data-title]="'languages.buttons.saveFavorite' | translate" 
                aria-expanded="false" (click)="showhidePopfirst()">
                <img  id="filterImage" src="assets/icons/iconfavorie-hover.svg" class="filter-image">
              </button>

              <div class="dropdown-menu popup" aria-labelledby="dropdownMenuButton" [ngClass]="{'hidden': !isDropdownVisible}">

                <div class="popup-content" (click)="stopClickPropagation($event)">
                  <div class="row mb-2">
                    <div class="col-10 d-flex justify-content-start">
                      <span class="title">{{'languages.buttons.addFavorite' | translate}}</span>
                    </div>
                    <div class="col-2 d-flex justify-content-center">
                      <button type="button"  (click)="showhidePop()" class="closeIconn">&times;</button>
                    </div>


                    
                  </div>
                  <div class="row">
                  <div class=" col-md-12">
                    <div class="form-group w-100">
                      <mat-form-field class="full-width">
                        <mat-label>{{'languages.affaire.name' | translate}}</mat-label>
                        <input matInput [(ngModel)]="Libellefiltre" (keyup)="detecterror()" name="Libelle">
                          <mat-hint *ngIf="errrorFilterLibelle==true" >
                            {{'languages.produit.nomOblig' | translate}}
                          </mat-hint>
                        </mat-form-field>
                    </div>
                  </div>
                  <div class=" col-md-12">
                    <div class="form-group w-100">
                <mat-form-field class="w-100 p-0 m-0">
                    <mat-label> {{'languages.affaire.myCollections' | translate}}</mat-label>

                    <mat-chip-list  #RechercheCollection>
                      <mat-chip *ngFor="let collection of listCollectionSelected" [selectable]="selectable"
                       [removable]="removable" (removed)="removeCollection(collection)"
                          >
                          {{collection.libelle}}
                          <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                      </mat-chip>
                      <input #collInput placeholder="{{'languages.affaire.myCollections' | translate}}"
                      matInput [formControl]="collectionForm" [matAutocomplete]="auto"
                          [matChipInputFor]="RechercheCollection" [readonly]="isAutocompleteReadOnly" >
                  </mat-chip-list>
                    <mat-autocomplete  (optionSelected)="onSelectCollection($event)" autoActiveFirstOption [displayWith]="displayCollFn" #auto="matAutocomplete">
                        <mat-option *ngFor="let cardItem of filteredCollection |async" [value]="cardItem"  (click)="stopClickPropagation($event)">
                            {{cardItem.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint *ngIf="errrorCollectionLibelleA==true" >
                       {{'languages.affaire.libelleCollectionOblig' | translate}}
                    </mat-hint>
                  </mat-form-field>



                  </div>
                </div>
                <div class="col-md-12  d-flex justify-content-center align-items-center"
                [ngClass]="{'disabled': !isClickEnabled}">
                 <button [disabled]="!isClickEnabled" class="row newCollection align-items-center" (click)="addNewCollection()">
                  <i class="fa-solid fa-circle-plus mr-1 "></i>
                   {{'languages.affaire.newCollection' | translate}}  
                 </button>
               </div>
               <div class="col-md-12 " *ngIf="checkAddCollection">
                <div class="form-group w-100">
                  <mat-form-field class="full-width">
                    <mat-label> {{'languages.affaire.newCollection' | translate}} </mat-label>
                    <input matInput [(ngModel)]="LibelleCollection" name="LibelleCollection">
                      <mat-hint *ngIf="errrorCollectionLibelle==true" >
                        {{'languages.affaire.libelleCollectionOblig' | translate}}
                      </mat-hint>
                  </mat-form-field>
                </div>
              </div>
              </div>


                  <div class="row justify-content-center align-items-center footer-row">
                   

                  <button class="  btn-dt-saved-filter2" type="button" (click)="AddFilterCollection()" [disabled]="isSaveDisabled" >
                    <i class="fa-solid fa-bookmark fa-lg"></i>
                    {{'languages.buttons.enregistrer' | translate}}
                  </button> 
                  <button  type="button" class=" ml-2 btn-dt-cancel-popup" (click)="resetAddFilter()">
                    <i class="fa-solid fa-circle-xmark fa-icon-style mr-1 fa-lg"></i>
                    {{'languages.buttons.annuler' | translate}}
                  </button>

                  </div>
                </div>
              </div>
            </div>
