<div class="row col-12 mt-3">
  <mat-accordion class="example-headers-align w-100"  multi>
      <mat-expansion-panel [(expanded)]="isExpanded"  id="{{index}}">
            <mat-expansion-panel-header>

               
                
                <mat-panel-title class=" row w-100">
                  <div class="col-8">
                    <h2 class="font-primary-geoprod">
                      <i class=" {{formObjectAssurer?.icon}} fa-lg mr-1" *ngIf="formObjectAssurer?.icon && formObjectAssurer?.icon !== 'None' "></i>
                         {{formObjectAssurer.nom}}</h2>
                        </div>
                        <div class="col-4 d-flex justify-content-end align-items-center">
                          <i class="fa-solid fa-clone mx-1 iconDuiplicate" (click)="$event.stopPropagation();"  (click)="duplicateobjet(formObjectAssurer)"></i>
                          <i class="fa-solid fa-trash mx-1 iconTRash " (click)="$event.stopPropagation();" (click)="deleteobjet(formObjectAssurer)" ></i>
                        </div>
                        
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
            </mat-expansion-panel-header>

    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form" (change)="changeValue($event)" ></formly-form>

  </mat-expansion-panel>
</mat-accordion>
</div>
