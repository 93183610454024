<div class=" card bg-white border-0 p-3 " >
    <div class="card-doc-title ml-3 row">
        <i class="fa-solid fa-tickets-simple mr-2 mt-2 fa-lg " [ngClass]="{'ml-2': currentLanguage === 'ar'}" ></i>
        {{'languages.ticket.sousTicket' | translate}} 
    </div>
    <button type="button" class="btn-document-add btn-position-add"  [ngClass]="{'btn-position-add-arabic': currentLanguage === 'ar'}"  [attr.data-title]="'languages.ticket.addTicket' | translate" data-toggle="modal" data-target="#AjoutTicketModal">
        <i class="fa-solid fa-plus fa-lg"></i>    </button>
    <ng-container *ngIf="show_loading_list">

        <div class="row mb-3 mt-3">
            <div class="text-center m-auto ">
                <mat-spinner [diameter]="30"></mat-spinner>

            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!show_loading_list">
        <div class=" mt-3 scrollbar" [ngClass]="{'table-scroll': listSousTicket?.length > 5,'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar' }">
            <table class="table align-items-center" id="dataTable1">
                <thead class="head-table">
                    <tr>
                        <th>{{'languages.opportunite.label_creationdate' | translate}}</th>
                        <th> {{'languages.communication.creator' | translate}}</th>
                        <th>{{'languages.ticket.Category' | translate}}</th>
                        <th>{{'languages.alerts.subject' | translate}}  </th>
                        <th>{{'languages.navbar.Détails' | translate}} </th>
                        <th>{{'languages.listing.Statut' | translate}}</th>
                    </tr>
                </thead>
                <tbody class="body-table">
                    <tr *ngFor="let ticket of listSousTicket">
                        <td> {{ticket.date_creation}}</td>
                        <td>{{ticket.createur}}</td>

                        <td>{{ticket.categorie}}</td>
                        <td>{{expandFileName(ticket?.title)}}</td>
                        <td>
                            <button *ngIf="ticket.id" type="button" class="btn-dt-link" (click)="NaviagateToTicketDetails(ticket.id)">
                                <i class="fa-solid fa-link fa-icon-style"></i>
                            {{'languages.ticket.lienTicket' | translate}} 
                          </button>
                        </td>
                        <td>
                            <div class="label-status " [ngStyle]="{'background':ticket ?.statut_background_color}">
                                <span class="pt-2" [ngStyle]="{'color':ticket?.statut_color}">
                            <i class=" {{ticket?.statut_icon}} mr-1" ></i>
                            {{ticket.statut_libelle}}
                          </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="listSousTicket?.length < 1" class="text-center font-weight-bold pt-1">
                <div class="col-12  d-flex justify-content-center">
                    <img src="assets/icons/nodata.svg">

                  </div>
                  <div class="col-12  d-flex justify-content-center">
                    <p class="noData">{{'languages.affaire.noDataAvailable' | translate}}</p>
                  </div>
            </div>
        </div>
    </ng-container>

  
    <div class="modal fade" id="AjoutTicketModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 60rem; ">
                <div class="modal-body p-0">
                    <app-add-tickets  [type]="choix" [idTicket]="ticket_id" [prospect]="prospect_id" [id]="id_entity" [fromDetails]="fromDetails" [sousTicket]="true"></app-add-tickets>
                                </div>
            </div>
        </div>
    </div>

    </div>
  
  
  

  
  

 
  