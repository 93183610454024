import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Process} from '../../entity/Process';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.css']
})
export class StatusBarComponent implements OnInit,OnChanges {
  @Input() listOfProcesses: Process [] = [];
  @Output() onClickStatus: EventEmitter<number> = new EventEmitter<number>();
  widthPercent:number=0;
  currentLanguage: string;
  constructor(private translate: TranslateService) { }
  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.changeLang();
  }

  StatusClicked(i: number) {
    this.onClickStatus.emit(i);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentLanguage = this.translate.currentLang;
   
    this.widthPercent=100/this.listOfProcesses?.length
  }

  changeLang() {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.currentLanguage = this.translate.currentLang;
      });
    });
  }
}
