import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { ApiAdminService } from 'src/app/services/ApiAdmin/api-admin.service';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { ListOrganisme } from 'src/app/entity/Generic/ListOrganisme';
import { ApiOrganismeService } from 'src/app/services/ApiOrganisme/api-organisme.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { element } from 'protractor';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-list-organisme',
  templateUrl: './list-organisme.component.html',
  styleUrls: ['./list-organisme.component.css'],
})
export class ListOrganismeComponent implements OnInit, OnDestroy, AfterViewInit {
  listeOfUnsbscribeWebService: Subscription[] = [];

  listOrganisme: ListOrganisme[] = [];
  dataTable: any;
  miniListing: any;
  alerts: any;
  private unsubscribeGetListOrganisme: Subscription;
  buttons: any;
  inputSubject = new Subject<string>();
  currentLanguage: string;
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private routes: Router,
    private apiAdminServices: ApiAdminService,
    private apiOrganismeService: ApiOrganismeService,
    private chRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.inputSubject.pipe(debounceTime(1000)).subscribe((inputValue: string) => {
      this.filter(inputValue);
    });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }

  ngOnDestroy(): void {
    // this.listeOfUnsbscribeWebService.forEach((element) => {
    //   element.unsubscribe();
    // });
    if (this.unsubscribeGetListOrganisme) {
      this.unsubscribeGetListOrganisme.unsubscribe();
    }
    const currentRoute = this.routes.url;
    if (!currentRoute.includes('gestion-organisme')) {
      localStorage.removeItem('search');
      localStorage.removeItem('page');
    } else {
      localStorage.setItem('search', this.search);
      localStorage.setItem('page', this.page.toString());
    }
  }

  ngAfterViewInit(): void {
    this.chRef.detectChanges();
  }

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionOrganisme);
      this.miniListing = object.miniListing;
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.currentLanguage = this.translate.currentLang;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.gestionOrganisme);
        this.miniListing = object.miniListing;
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.currentLanguage = this.translate.currentLang;
      });
    });
    this.getListOrganisme();
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  retourToList() {
    this.routes.navigate(['/gestion-organisme']);
  }

  async getListOrganisme() {
    this.showloader();
    if (localStorage.getItem('page')) this.page = parseInt(localStorage.getItem('page'));
    if (localStorage.getItem('search')) this.search = localStorage.getItem('search');
    const unsbscribeGetListOrganisme = this.apiOrganismeService
      .GetListOrganismeV2(this.limit, this.page, this.search)
      .subscribe((data: any) => {
        this.listOrganisme = data?.Data;
        this.totalLength = data.totalCount;
        this.calculate_nb_pages(this.totalLength);
        this.chRef.detectChanges();
        this.hideloader();
      });

    this.listeOfUnsbscribeWebService.push(this.unsubscribeGetListOrganisme);
  }

  alertConfirmUpdateActive(idOrg, status) {
    if (status === 0) {
      swal
        .fire({
          title: this.alerts.confirmClosingSubOrganisme,
          icon: 'info',
          showConfirmButton: true,
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: this.buttons.fermer,
          confirmButtonText: this.buttons.confirmer,
          confirmButtonColor: '#28a8d2',
        })
        .then((result) => {
          if (result.isConfirmed) this.changeStatus(idOrg, status);
        });
    } else {
      this.changeStatus(idOrg, status);
    }
  }

  alertError(err?) {
    swal.fire({
      title: this.alerts.errorUpdatingOrg,
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  changeStatus(idOrg: string, status: number) {
    this.showloader();
    this.apiAdminServices.ActivateDeactivateOrganisme(idOrg, status).subscribe(
      (response) => {
        this.hideloader();
        this.toastr.success(this.alerts.Successoperation);
        // window.location.reload()
        this.getListOrganisme();
      },
      (error) => {
        this.hideloader();
        this.alertError(error.error.message);
      }
    );
  }

  limit = 15;
  page = 1;
  totalPages = 0;
  totalLength: any;
  intervale = 1;
  search: string = '';

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.removeItem('search');
    localStorage.removeItem('page');
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    localStorage.setItem('page', this.page.toString());
    this.getListOrganisme();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    localStorage.setItem('page', this.page.toString());
    this.getListOrganisme();
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.getListOrganisme();
      }
    }
  }

  updateListOrganisme() {
    this.page = 1;
    this.getListOrganisme();
  }

  filter(event) {
    this.page = 1;

    localStorage.setItem('search', this.search);
    localStorage.setItem('page', this.page.toString());
    this.getListOrganisme();
  }
}
