<div class="">
  <div class="modal-header">

    <h3 class="text-center title justify-content-center m-0 w-100">{{"languages.prospect.chooseProspect" | translate}}</h3>
  
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="p-3 mb-3"  [ngClass]="{'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar'}">
      <table id="Table" class="table">
        <thead class="head-table">
          <tr>
            <th>#</th>
            <th> {{"languages.prospect.nom" | translate}}</th>
            <th> {{"languages.prospect.prenom" | translate}}</th>
            <th>{{"languages.prospect.email" | translate}}</th>
            <th>{{"languages.prospect.phone" | translate}}</th>
            <th>{{"languages.listing.Action" | translate}}</th>
          </tr>
        </thead>
        <tbody class="body-table">
          <tr  *ngFor="let item of list_prospects; let index = index" (click)="toggleSelection(index);sendDataIndismiss(item)" class="hover-effect">
            <td>{{ index+1 }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.surname }}</td>
            <td>{{ item.adresse_mail }}</td>
            <td>{{ item.mobile ? item.mobile  : "-" }}</td>
            <td>
              <img src="/assets/img/Groupe2.svg" *ngIf="!isSelected(index)" (click)="sendDataIndismiss(item)">
              <img src="/assets/img/Groupe1.svg" *ngIf="isSelected(index)" >
            </td>
          </tr>
        </tbody>
      </table>
      <ng-container *ngIf="loader">
          <div class="col-12 text-center d-flex justify-content-center">
              <mat-spinner [diameter]="20"></mat-spinner>
          </div>
      </ng-container>
      <div *ngIf="list_prospects?.length === 0 && !loader" class="row pt-2">
    
        <div class="col-12  d-flex justify-content-center">
          <img src="assets/icons/nodata.svg">
        </div>
        <div class="col-12  d-flex justify-content-center">
          <p class="noData">{{'languages.affaire.noDataAvailable' | translate}}</p>
       </div>
      <div class="navbar-center mb-4 row"  *ngIf="list_prospects.length >0">
     
        <div class="col justify-content-center d-flex">
          <nav aria-label="Page navigation example"  *ngIf="totalPages>0">
            <ul class="pagination pg-blue">
              <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                <a class="page-link-symbol">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2" >
                <a class="page-link-symbol-chevron" aria-label="Previous">
                  <i class="fa fa-chevron-left "></i>
                </a>
              </li>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+page-3>0" class="page-item">
                  <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page - 3}}</a>
                </li>
              </ng-container>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+page<totalPages" class="page-item">
                  <a (click)="changepage(i+page)" class="page-link mt-2 border rounded" 
                  [ngClass]="{'active-page':page==(i+page)}">
                  {{i + page}}
                </a>
                </li>
              </ng-container>
              <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
              <li class="page-item">
                <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                  [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
              </li>
              <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                <a class="page-link-symbol-chevron" aria-label="Next">
                  <i class="fa fa-chevron-right"></i>
                </a>
              </li>
              <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                <a class="page-link-symbol">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
            </ul>
          </nav>
      </div>
   
      </div>
    </div>
  </div>
</div>
