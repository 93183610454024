import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApiVoIPService } from '../services/ApiVoIP/api-voip.service';
import { environment } from '../../environments/environment';
import { Configuration } from '../services/configuration';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import swal from 'sweetalert2';
import { ApiAuthentificationService } from '../services/ApiAuthentification/api-authentification.service';
import { UserLogin } from '../login/login.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
})
export class AdminLayoutComponent implements OnInit {
  sideBar_status = false;
  user: UserLogin;
  currentLanguage: string;
  constructor(
    private apiVoIPService: ApiVoIPService,
    private apiAuthentificationService: ApiAuthentificationService,
    private _renderer2: Renderer2,
    private router: Router,
    configuration: Configuration,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.route.queryParams.subscribe((params) => {
      const accessToken = localStorage.getItem('Acces_Token') || '';
      if (params?.idSession) {
        localStorage.setItem('Acces_Token', params?.idSession);
        configuration.apiKeys.idSession = params?.idSession;
      } else if (accessToken === '') {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnInit(): void {
    this.getVoIPlogin();
    
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.currentLanguage = this.translate.currentLang;
    
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.currentLanguage = this.translate.currentLang;
    
    });
   /*  timer(0, 100000).subscribe(() => {
      this.apiAuthentificationService.checksessionGet().subscribe((response) => {
        if (response.alert === true) {
          this.alertWarning(response);
        }
      });
    }); */

  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.apiAuthentificationService.resetSessionTimer();
  }

  alertWarning(data) {
    swal
      .fire({
        title: '',
        text: 'La session sera expiré dans deux minutes',
        icon: 'warning',
        showConfirmButton: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Rester connecté',
        confirmButtonColor: '#e75e5e',
        cancelButtonText: 'Déconnecter',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.apiAuthentificationService.UpdatesessionGet().subscribe((response) => {});
        }
        if (result.isDismissed) {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        this.removeModalBackdrop();
      });
  }

  removeModalBackdrop() {
    const backdropElements = document.querySelectorAll('.modal-backdrop.fade.show');
    backdropElements.forEach((element) => {
      element.classList.remove('modal-backdrop', 'fade', 'show');
    });
    document.body.style.overflow = 'auto';
  }

  configWebPhone(key: string) {
    const script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src = environment.ipbx_server + `/webphone/widget/init?ssokey=` + key;
    this._renderer2.appendChild(this._document.body, script);
  }

  changeSideBarStatus($event: any) {
    this.sideBar_status = $event;
  }

  getVoIPlogin() {
    /* this.apiVoIPService.voipLoginGet().subscribe((data: JSON) => {
      this.configWebPhone(data['WP_KEY']);
    }); */
  }
}
