<div class="card-bloc  bg-white border-0 p-3 ">
    <div class="card-doc-title ml-3 row">
        <i class="fa-solid fa-file-import mr-2 mt-2 fa-lg " [ngClass]="{'ml-2': currentLanguage === 'ar'}"></i>

         {{'languages.document.PorteDocuments' | translate}}
    </div>
    <div class="row p-3">
        <div class="col-md-6 ">
            <div class="div-list-no-exist  p-3 mb-2" id="ex1">
            <div class="row">
                <label class="label-title mb-2 ml-2 title-secc">{{'languages.document.documentToBeAdded' | translate}}</label>
            </div>
            <span class="d-flex justify-content-center mb-3">
                <ng-container *ngIf="show_loading_add">
                        <mat-spinner [diameter]="30"></mat-spinner>
                </ng-container>
            </span>
            <div *ngFor="let noExist of listDocuments; let i=index" id="accordionFolder">
                <app-shared-documents (shareDocument)="getSharedDocument($event)" [documents_rajoutes]="false" [type]="'T'" [exist]="noExist" [i]="index" [show_three_points]="true" [switch_upload_ticket]="true" [tickets_id]="tickets_id"></app-shared-documents>
            </div>
        </div>
    </div>
        <div class="col-md-6">
            <div id="ex2" class=" scrolling div-list-exist  p-3">
            <div class="row">
                <label class="label-title mb-2 title-sec">{{'languages.document.documentRajoute' | translate}}</label>
            </div>
            <div class="d-flex justify-content-center mb-3">
                <ng-container *ngIf="show_loading_doc">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </ng-container>
            </div>
            <div *ngFor="let exist of listDocuments; let i=index" id="accordionFolder">
                <app-shared-documents [type]="'T'" (shareDocument)="getSharedDocument($event)" [documents_rajoutes]="true" [exist]="exist" [i]="index" [show_three_points]="true" [switch_upload_ticket]="true" [tickets_id]="tickets_id"></app-shared-documents>
            </div>

        </div>
    </div>
</div>
    <span class="text-dark mt-4">( * ) {{'languages.document.documentOblig' | translate}}</span>
</div>
