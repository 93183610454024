<div class="card-headd text-center  ">
  <div class="row details-header">
    <button type="button" class="btn-dt-retourr " (click)="returnToMaileva()">
      <div class="icon-position">
        <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
      </div>
    </button>
    <div class="row">
     


      <button class="statut-envoieCancel text-center d-flex align-items-center "  (click)="alertConfirm()"  *ngIf="maileva?.statut===1">
<i class="fa-solid fa-circle-xmark  fa-lg mr-2"></i>
        <b>{{'languages.communication.AnnulerLenvoie' | translate}}</b>
      </button>
      
    </div>
   
  </div>
</div>
<div class="row ">
  <div class="col-4 w-100">
    <div class="card-head w-100 custem-margin-card">
      <div class="row justify-content-center">
        <div class="col-12" *ngIf="(maileva.id_opp ===null || maileva.id_opp ==='')||(maileva.id_affaire ===null || maileva.id_affaire ==='')">
          <label class="label-card-info"></label>
        </div>
        <div class="text-nowrap col-12 labell">
          {{'languages.communication.identifiantCourrier' | translate}}
          <label class="label-card-info">
            {{maileva?.id}}
          </label>
        </div>
        <div class="text-nowrap col-12 labell" *ngIf="maileva.id_opp!==null && maileva.id_opp!==''" >
          {{'languages.opportunite.opportuniteNum' | translate}}
          <a class="a-style d-inline-block ml-2" (click)="NaviagateToDetails(maileva.id_opp,'Opportunite')"
          style="cursor: pointer;">
          <label class="label-card-info">
            {{maileva.id_opp}}
          </label>
          </a>
        </div>
        <div class="text-nowrap col-12 labell"*ngIf="maileva.id_affaire!==null && maileva.id_affaire!==''" >
          {{'languages.affaire.nContrat' | translate}} :
         
            <a class="a-style d-inline-block ml-2" (click)="NaviagateToDetails(maileva.id_affaire,'affaire')"
            style="cursor: pointer;">
            <label class="label-card-info">
           {{maileva.id_affaire}}
          </label>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4 w-100">
    <div class="card-head custem-margin-card w-100">
      <div class="row justify-content-center">
        <div class="col-12" >
          <label class="label-card-info"></label>
        </div>
        <div class="text-nowrap col-12 labell">
          {{'languages.communication.exp' | translate}} :
          <label class="label-card-info">
            {{maileva?.nomprenom_exp}}
          </label>
        </div>
      
        <div class="text-nowrap col-12 labell" >
          {{'languages.communication.destinataire' | translate}} :
         
            <label class="label-card-info">
              {{maileva?.nomprenom_dest}}
          </label>


        </div>


      </div>
    </div>
  </div>
  <div class="col-4 w-100  d-flex">
    <div class="card-head w-100 custem-margin-card-2 custem-margin-card">
      <div class="row justify-content-center">
        <div class="col-12" >
          <label class="label-card-info"></label>
        </div>
        <div class="text-nowrap labell col-12">
          {{'languages.communication.createdIn' | translate}} :
          <label class="label-card-info">
            {{maileva?.date | date:'dd-MM-yyyy HH:mm'}}
          </label>
        </div>
        <div class="text-nowrap col-12 d-flex" >
          <div class="col-2 mt-2 pl-0 labell">
            {{'languages.listing.Statut' | translate}} :
          </div>
        
      
           
                <div class="col-6 mb-1   header-ticket-status d-flex justify-content-center align-items-center"  [ngStyle]="{'background':maileva.statut_details?.backgroundcolor, 'border' : '2px solid ' + maileva.statut_details?.border ,'color':maileva.statut_details?.couleur }" *ngIf="maileva.statut_details?.icon">
                   <i class=" {{maileva?.statut_details?.icon}} mr-2 "  ></i>

                              {{maileva.statut_details?.libelle.split('(')[0].trim()}}

                </div>
                
         
        
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row custem-margin">
  <div class="col" style="max-width: 248px;">
    <div class="sidebar-affaire " id="scrollbar-affaire">
      <div class=" card-affaire bg-white text-center border-0">
        <div>
          <div class="col py-1">
            <button (click)="scroll('DetailsCourrier')" class="focus-style d-block border-0 full-width text-left pl-3">
              <label class="align-details-icon ">
                <i class="fa-solid fa-circle-info fa-lg"></i>
                
              </label>
              <span> {{'languages.communication.detailsCourrier' | translate}}</span>
            </button>
          </div>
          <div class="col py-1">
            <button (click)="scroll('doc')" class=" focus-style d-block border-0 full-width text-left pl-3">
              <label class="align-details-icon">
                
                <i class="fa-solid fa-file-import fa-lg"></i>
              </label>
              <span>{{'languages.document.PorteDocuments' | translate}}</span>
            </button>
          </div>
        
          <div class="col py-1">
            <button (click)="scroll('exp')" class=" focus-style d-block border-0 full-width text-left pl-3">
              <label class="align-details-icon">
                <i class="fa-solid fa-right-left fa-lg"></i>
              </label>
              <span>{{'languages.communication.expDesti' | translate}}</span>
            </button>
          </div>
          <div class="col py-1">
            <button (click)="scroll('historique')" class=" focus-style d-block border-0 full-width text-left pl-3">
              <label class="align-details-icon">
                
                <i class="fa-solid fa-clock-rotate-left fa-lg"></i>
              </label>
              <span>{{'languages.communication.historiqueCourrier' | translate}}</span>
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="col ">
    <div class="card-body2 row border-0 bg-white  p-4" id="DetailsCourrier">
      <div class="col-6 iconDetail card-doc-title d-flex align-items-center">

<i class="fa-solid fa-circle-info mr-2 fa-lg"></i>
        {{'languages.communication.detailsCourrier' | translate}}


      </div>
      <div class=" col-6 d-flex justify-content-end ">


      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.communication.nomPrenomCreator' | translate}}</label>
          <h4 class="inputt" *ngIf="maileva?.nomprenom_exp"><b>{{maileva?.nomprenom_exp}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.nomprenom_exp"><b>--</b></h4>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.alerts.subject' | translate}}</label>
          <h4 class="inputt" *ngIf="maileva?.subject"><b>{{maileva?.subject}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.subject"><b>--</b></h4>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.communication.referenceMaileva' | translate}}</label>
          <a target="_blank " href="maileva?.notif_link">
            <h4 class="inputt" *ngIf="maileva?.ref_notif"><b>{{maileva?.ref_notif}}</b>
              <i class="fa-solid fa-link mr-2"></i>
            </h4>
          </a>
          <h4 class="inputt" *ngIf="!maileva?.ref_notif"><b>--</b></h4>

        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.communication.typeEnvoie' | translate}}</label>
          <h4 class="inputt" *ngIf="maileva?.type_envoie"><b>{{maileva?.type_envoie}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.type_envoie"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.communication.typeEnvelop' | translate}}</label>
          <h4 class="inputt" *ngIf="maileva?.type_envelope"><b>{{maileva?.type_envelope}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.type_envelope"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.campagne.Couleur' | translate}}</label>
          <h4 class="inputt" *ngIf="maileva?.couleur"><b>{{maileva?.couleur}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.couleur"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.communication.ImprimrectoVerso' | translate}}</label>
          <h4 class="inputt"><b>{{maileva?.recto_verso ? 'Oui' : 'Non'}}</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title"> {{'languages.communication.dateAffrachis' | translate}}</label>
          <h4 class="inputt" *ngIf="maileva?.date_livraison"><b>{{maileva?.date_livraison}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.date_livraison"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">
            {{'languages.communication.dateEnvoiPlanifie' | translate}}
          </label>
          <div class="row inputt">
            <div class="col-1-mg-2" *ngIf="!showPlanifier && maileva?.statut===1" (click)="onclickPlanifier()">
              <i class="fa-solid fa-pen m-1 edit" style="color: #383838;"></i>
            </div>

            <h4 *ngIf="!showPlanifier"><b>{{datepalanifier | date: "dd-MM-yyyy"}}</b></h4>

            <div *ngIf="showPlanifier">
              <input type="date" class="form-control date-picker" id="date_debut" name="date_debut"
                [ngModel]="datepalanifier " (ngModelChange)="datepalanifier = $event" [value]="datepalanifier"
                [min]="getTodayDate()">
              <button class="btn btn-primary mt-4 mr-1" style="width: 45%;" (click)="updateDateplanifie()">
                <i class="fa-solid fa-check"></i>
                 </button>
              <button class="btn btn-danger mt-4 mr-1" style="width: 45%;" (click)="onclickPlanifier()">
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.communication.nbrTotalPages' | translate}}</label>
          <h4 class="inputt" *ngIf="maileva?.nbr_page"><b>{{maileva?.nbr_page}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.nbr_page"><b>--</b></h4>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.communication.nbrPageFacture' | translate}}</label>
          <h4 class="inputt" *ngIf="maileva?.nbr_page"><b>{{maileva?.nbr_page -1 }}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.nbr_page"><b>--</b></h4>
        </div>
      </div>


      <div class="col-3">
        <div class="form-group">
          <label class="title">{{'languages.communication.tarifCourrier' | translate}}</label>
          <h4 class="inputt" *ngIf="maileva?.tarif_courrier"><b>{{maileva?.tarif_courrier |currency:currency:'symbol':formatCurrency:'fr-FR'}}</b></h4>
          <h4 class="inputt" *ngIf="!maileva?.tarif_courrier"><b>--</b></h4>
        </div>
      </div>

      <div class="row col-12" *ngIf="showReceptionRow()">
        <div class="col-6" style="margin-left: -15px;" *ngIf="maileva?.avis_reception!=null">
          <div class="form-group">
            <label class="title">{{'languages.communication.avisReception' | translate}}</label>
            <a class="col-md-4  " *ngIf="maileva?.avis_reception" href="{{maileva?.avis_reception}}" target="_blank">
              <div class=" col-12  fileComment py-2 m-2 d-flex  align-items-center " >
         
              <img  src='../../../assets/icons/FileIcon.svg'>
                  
                    
             <span class="file-name  links ml-2">  {{expandName(getDocumentName(maileva?.avis_reception),50)}} </span>
         </div>
          </a>
            <h4 *ngIf="!maileva?.avis_reception"><b>--</b></h4>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="title">{{'languages.communication.receptionDate' | translate}}</label>
            <h4 *ngIf="maileva?.ar_date"><b>{{maileva?.ar_date}}</b></h4>
            <h4 *ngIf="!maileva?.ar_date"><b>--</b></h4>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label class="title"></label>
          </div>
        </div>
      </div>
      <div class="row col-12" >
        <div class="col-6">
          <div class="form-group" style="margin-left: -15px;">

            <label class="title d-flex  ">{{'languages.communication.preuveElectro' | translate}}
              <div class="ml-2">
              <i class=" fa-solid fa-search fa-lg fa-lg fa-1x " 
              style="cursor:pointer"
              (click)="docPreuve()"
              [disabled]="maileva.preuve_attachement || maileva?.statut===1 && loading"
              *ngIf=" maileva.id && maileva?.statut ===6 && !maileva?.preuve_attachement && !loading"></i>
            <mat-spinner *ngIf=" loading " [diameter]="20"></mat-spinner>
          </div></label>
            
  <p class="noDataDispo " *ngIf="!maileva?.preuve_attachement">{{'languages.communication.noPreuveDepot' | translate}}</p> 

            <a class="row  pr-2 " *ngIf="maileva?.preuve_attachement" href="{{maileva?.preuve_attachement}}" target="_blank">
              <div class=" col-12  fileComment py-2 m-2 d-flex  align-items-center " >
         
              <img  src='../../../assets/icons/FileIcon.svg'>
                  
                    
             <span class="file-name  links ml-2">  {{expandName(getDocumentName(maileva?.preuve_attachement),50)}} </span>
         </div>
          </a>
           
           
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label class="title">{{'languages.communication.dateReceptionElectro' | translate}}</label>
            <h4 *ngIf="maileva?.preuve_date"><b>{{formatDate(maileva?.preuve_date)}}</b></h4>
            <h4 *ngIf="!maileva?.preuve_date"><b>--</b></h4>
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label class="title"> {{'languages.communication.CodePreuveElecto' | translate}}</label>
            <h4 *ngIf="maileva?.preuve_code"><b>{{maileva?.preuve_code}}</b></h4>
            <h4 *ngIf="!maileva?.preuve_code"><b>--</b></h4>
          </div>
        </div>

      </div>



      <div class="col-3" *ngIf="maileva?.statut_details?.code =='N' ">
        <div class="form-group">
          <label class="title"></label>
          <div></div>
          <h4 class="statut-envoieRenvoye text-center">
            <div class="row" (click)="resendMaileva()">
              <div class="col-2">
                <i class="fa-solid fa-paper-plane mr-2"></i>
              </div>
              <div class="col">
                <b>{{'languages.communication.reEnvoye' | translate}}</b>
              </div>
            </div>
          </h4>
        </div>
      </div>


    </div>

    <div class=" card-body2  border-0 bg-white  p-4 " style="margin-right: -12px; margin-left: -12px;">
      <div class="row ">
        <div class="card-doc-title ml-3 row" id="doc">
<i class="fa-solid fa-file-import mr-2 mt-2 fa-lg"></i>
          {{'languages.document.PorteDocuments' | translate}}
        </div>
      </div>
      <div class="row scrollbar fileContainerChild">

        <a class="col-md-4  " *ngIf="maileva?.file_courrier" href="{{maileva?.file_courrier}}" target="_blank">
            <div class=" col-12  fileComment py-2 m-2 mt-4  d-flex  align-items-center " >
       
            <img  src='../../../assets/icons/FileIcon.svg' alt="file icon">
                
                  
           <span class="file-name  links ml-2">  {{expandName(getDocumentName(maileva?.file_courrier),38)}}  </span>
       </div>
        </a>

  </div>
    </div>
    <div class=" card-body2  border-0 bg-white  p-4 " style="margin-right: -12px; margin-left: -12px;">
      <div class="card-doc-title ml-3 row" id="exp">
<i class="fa-solid fa-right-left mr-2 mt-2 fa-lg"></i>
        {{'languages.communication.expDesti' | translate}}
      </div>
      <div class="row p-3 ">
        <div class="col-6 d-flex flex-column">
          <div class="card-body ">
            <h4 class="row titre">{{'languages.communication.detailsExp' | translate}}</h4>

            <div class="row">
              <div class="col-6 ">
                <div class="form-group">
                  <label class="title"> {{'languages.opportunite.label_nomandprenom' | translate}}</label>
                  <h4><b>{{maileva?.nomprenom_exp}}</b></h4>
                </div>
              </div>
              <div class="col-6 ">
                <div class="form-group">
                  <label class="title">{{'languages.communication.adressExp' | translate}}</label>
                  <h4><b>
                      {{maileva?.complement_adr_exp ? maileva?.complement_adr_exp :maileva?.ville_exp +" "+
                      maileva?.postal_code_exp }} {{
                      maileva?.bp_adresse_exp }}

                    </b></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 d-flex flex-column">
          <div class="card-body ">
            <h4 class="row titre"> {{'languages.communication.detailsDesti' | translate}}</h4>
            <div class="row">
              <div class="col-6 ">
                <div class="form-group">
                  <label class="title">{{'languages.opportunite.label_nomandprenom' | translate}}</label>
                  <h4><b>{{maileva?.nomprenom_dest}}</b></h4>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="title">{{'languages.communication.adressDesti' | translate}}</label>
                  <h4><b>
                      {{maileva?.complement_adr_dest ? maileva?.complement_adr_dest : maileva?.ville_dest +" "+
                      maileva?.postal_code_post}} {{
                      maileva?.bp_adresse
                      }}
                    </b></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="card-body2 bg-white p-4 border-0 mb-4">
      <div class="card-doc-title ml-3 row" id="historique">
<i class="fa-solid fa-clock-rotate-left mr-2 mt-2 fa-lg"></i>
        {{'languages.communication.historiqueCourrier' | translate}}
      </div>
      <mat-form-field style="width: 100%;">
        <input matInput (keyup)="applyFilter($event)" placeholder="{{'languages.miniListing.search' | translate}}" #input>
      </mat-form-field>
      <div class="mat-elevation-z8"  [ngClass]="{'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar'}">
        <table mat-table class="w-100" [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{'languages.opportunite.details.date' | translate}} </th>
            <td mat-cell *matCellDef="let row" class="text-center"> {{row.date_action | date:'dd-MM-yyyy HH:mm'}} </td>
          </ng-container>
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{'languages.opportunite.label_user' | translate}}</th>
            <td mat-cell *matCellDef="let row" class="text-center"> {{row.mail_user}} </td>
          </ng-container>
          <ng-container matColumnDef="commentaire">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{'languages.opportunite.details.commentaire' | translate}}</th>
            <td mat-cell *matCellDef="let row" class="text-center"> {{row.commentaire}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="3">{{'languages.affaire.noDataAvailable' | translate}} </td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
