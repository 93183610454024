import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
 
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.css'],
})
export class AssistantComponent implements OnInit {
  currentLanguage: string;
  constructor(private route: Router, private sharedMenuObserverService: SharedMenuObserverService,
    private translate : TranslateService) {}

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.currentLanguage = this.translate.currentLang;
      });
     });
    this.sharedMenuObserverService.updateMenu('Opportunités ➜ Détails ➜ Prospect');
  }

  public minDate: Date = new Date('20/10/2020');
  public maxDate: Date = new Date('29/12/2020');
  public value: Date = new Date('26/10/2020');

  lat = 51.678418;
  lng = 7.809007;

  navigate() {
    this.route.navigate(['dashboard/opportunities/details']);
  }
}
