import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { Location } from '@angular/common';
import { Prospect } from 'src/app/entity/Prospect';
import { Entreprise } from 'src/app/entity/Opportunity/Entreprise';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fiche-prospect',
  templateUrl: './fiche-prospect.component.html',
  styleUrls: ['./fiche-prospect.component.css'],
})
export class FicheProspectComponent implements OnInit {
   
  lat: string = '';
  lng: string = '';
  name: string = '';
  prospect_id: string = '';
  loading: boolean = true;
  @Input() prospect: Entreprise = new Entreprise();
  opportunite_id: string;
  directeTo: string;
  navbarTxt:string="";
  currentLanguage: string;
  constructor(
    private routes: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiProspectService: ApiProspectsService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.prospect_id = params.get('prospect_id');
      this.opportunite_id = params.get('id_entity');
      this.directeTo = params.get('route');
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt=object.navbar.Détails +' ➜ '+ object.navbar.ficheProspect
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      this.currentLanguage = this.translate.currentLang;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt=object.navbar.Détails +' ➜ '+ object.navbar.ficheProspect
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
        this.currentLanguage = this.translate.currentLang;
      });
    });
    this.getProspect();
  }

  retourToList() {
    if (this.directeTo === 'opportunite') {
      this.routes.navigate(['/opportunities/details/' + this.opportunite_id + '/prospect']);
    } else if (this.directeTo === 'ticket') {
      this.routes.navigate(['/ticketsandsupport/details/' + this.opportunite_id]);
    } else if (this.directeTo === 'affaire') {
      this.routes.navigate(['/affaires/details/' + this.opportunite_id]);
    } else {
      this.routes.navigate(['/prospectClient']);
    }
  }

  updateProspect($event: any, number: number) {}

  getProspect() {
    this.showloader();
    this.apiProspectService.getProspectId(this.prospect_id).subscribe(
      (data: Entreprise) => {
        this.prospect = data;

        this.lat = data.mainProspect.latitude;
        this.lng = data.mainProspect.longitude;
        this.name = data.mainProspect.surname + ' ' + data.mainProspect.name;
        this.changeDetector.detectChanges();

        this.hideloader();
      },
      (err) => {
        console.error(err);
      }
    );
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  getRelationProspect(idProspect: string): void {
    this.prospect_id = idProspect;
    this.getProspect();
    this.scrollToTop('scrollTop');
  }

  scrollToTop(el: string) {
    const element = document.getElementById(el);
    const offset = 120;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
}
