import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { IconFontAwesomeService } from 'src/app/services/ApiIconFontAwesome/icon-font-awesome.service';

export interface FontAwesomeIcon {
  [pack: string]: string;
}

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class IconPickerComponent implements OnInit  {
  @Output() onChangeIcon: EventEmitter<string> = new EventEmitter();
  @Output() onChangeNameIcon: EventEmitter<string> = new EventEmitter();
  @Output() checkedNameIcon: EventEmitter<boolean> = new EventEmitter();

  icons: FontAwesomeIcon[] = [];
  @Input() searchText: string = "";
  @Input() req = 'true';
  @Input() touched :boolean = false
  selectedStyle: string = 'fa-solid';
  loadingIcons: boolean;
  dataIcon: any;
  iconss: any[] = [];
  filteredIcons: { name: string, icon: { prefix: string, iconName: string } }[] = [];
  searchQuery: string = '';
  constructor( private iconFontAwesomeService: IconFontAwesomeService,
                private cdr : ChangeDetectorRef ) {}

  ngOnInit(): void {
    this.getFontAwesomeIcons();
    this.cdr.detectChanges();
  }
  
  ngOnChanges(changes: SimpleChanges) {
  
    if (changes.searchText) {
      this.searchQuery = changes.searchText.currentValue;
      this.selectedStyle = this.getStyleFromIconName(changes.searchText.currentValue);
      this.onChange(changes.searchText.currentValue)
      this.customIcons(this.dataIcon)
      
    }
  }

    simulateNgOnChanges(newSearchText: string) {
      const changes: SimpleChanges = {
        searchText: {
          currentValue: newSearchText,
          previousValue: this.searchText,
          firstChange: false,
          isFirstChange: () => false
        }
      };
      this.ngOnChanges(changes);
    }

  getStyleFromIconName(iconName: string): string {
    const styleParts = iconName?.split(' ');
    if (styleParts){
      for (const part of styleParts) {
        if (part.startsWith('fa-')) {
          return part; 
        }
      }
    }
   
    return 'fa-solid'; 
  }
  
  onSelectIcon(style :string ,icoName: string) {
  
    const icon_complte = icoName
    this.onChangeIcon.emit(icon_complte);
    this.checkedNameIcon.emit(true)
    this.touched = false;
    this.searchQuery=icoName
    this.filterIcons();
  }
  
  onChange(newValue) {
  
    this.searchText = newValue;
    this.onChangeIcon.emit(newValue);
    this.onChangeNameIcon.emit(newValue);

    if( newValue !== '' && !this.iconExists(newValue)){
          this.checkedNameIcon.emit(false)
         } else{
          this.checkedNameIcon.emit(true)
         }
 
  }


  async checkValidIcon(newValue: string): Promise<void> {
    const iconExists = await this.iconExists(newValue);
    if ( newValue !== '' && !iconExists) {
      this.checkedNameIcon.emit(false);
    } else {
      this.checkedNameIcon.emit(true);
    }
  }
  
  async iconExists(iconName: string): Promise<boolean> {
 
    while (this.loadingIcons) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  

    const exists = this.filteredIcons.some(icon => icon.name.toLowerCase() === iconName.toLowerCase());
    
    return exists;
  }
  

  getFontAwesomeIcons(): void {
    this.loadingIcons = true;
    this.iconFontAwesomeService.getFontAwesomeIcons().subscribe(
      (data) => {
        this.dataIcon = data;
        this.customIcons(data).then(() => {
          this.loadingIcons = false;
          this.cdr.detectChanges();
        });
      },
      (error) => {
        this.loadingIcons = false;
        this.cdr.detectChanges();
        console.error('Fetching error: ', error);
      }
    );
  }
  


  // getFontAwesomeIcons() {
  //   this.loadingIcons=true
  //   this.iconFontAwesomeService.getFontAwesomeIcons().subscribe(
  //     (data) => {
  //       this.dataIcon =data
  //       this.customIcons(data)
       
  //     },
  //     (error) =>{
  //       this.loadingIcons=false
  //       this.cdr.detectChanges();

  //       console.error('Fetching error: ', error)
  //     } 
      
  //   );
  // }
  
  // customIcons(icons) {
  //   if (!icons) {
  //     this.iconss = []; // Clear the icons array if icons is undefined or null
  //     return;
  //   }
  
  //   this.loadingIcons = true;
  //   this.iconss = Object.keys(icons).map((key) => ({
  //     name: this.selectedStyle + ' ' + 'fa-' + key,
  //     styles: icons[key].styles,
  //   }));
  //   this.filterIcons();
  //   this.loadingIcons = false;
  //   this.cdr.detectChanges();
  // }

  customIcons(icons): Promise<void> {
    return new Promise((resolve) => {
      if (!icons) {
        this.iconss = []; 
        resolve();
        return;
      }
  
      this.loadingIcons = true;
      this.iconss = Object.keys(icons).map((key) => ({
        name: this.selectedStyle + ' ' + 'fa-' + key,
        styles: icons[key].styles,
      }));
      this.filterIcons().then(() => {
        this.loadingIcons = false;
        this.cdr.detectChanges();
        resolve();
      });
    });
  }

  
  // filterIcons() {
    
  //   this.filteredIcons = this.iconss.filter(icon =>
  //     icon.name.toLowerCase().includes(this.searchQuery?.toLowerCase())
  //   );
  //   this.cdr.detectChanges();
  // }

filterIcons(): Promise<void> {
  return new Promise((resolve) => {
    this.filteredIcons = this.iconss.filter(icon =>
      icon.name.toLowerCase().includes(this.searchQuery?.toLowerCase())
    );
    this.cdr.detectChanges();
    resolve();
  });
}


  
  getFilteredIcons(): any[] {
    return this.iconss.filter((icon) =>
      icon.name.includes(this.searchQuery.toLowerCase())
    );
  }

  onSearchChange(query: string) {

    this.searchQuery = query;

    this.filterIcons();
  }


  async onStyleChange(value): Promise<void> {

    this.selectedStyle = value ;
    await  this.customIcons(this.dataIcon)
    if (this.filteredIcons?.length === 0) {
     this.searchQuery=""
    this.searchText=""
    this.onChangeIcon.emit(this.searchText);
    this.checkedNameIcon.emit(true)
    }
    
   
  }
}

