import {
  Component,
  EventEmitter,
  Injectable,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  Input,
  OnChanges,
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { of as ofObservable, Observable, BehaviorSubject } from 'rxjs';
import { ApiStatusService } from 'src/app/services/ApiStatus/api-status.service';
import { tree } from 'src/app/entity/TreeView';
import { RendezVous } from 'src/app/entity/Opportunity/FilterOpportunite';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
 
import { Tags } from 'src/app/entity/Parametres/Tags/Tags';
import { ApiTagsService } from 'src/app/services/api-tags.service';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { AccessList } from 'src/app/entity/accessList';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

class ListGet {
  list_affaires: any = [];
  list_opportunites: any = [];
}
class BodyOppUpdated {
  action_statut: boolean;
  statut: any;
  list_opportunites: any;
  action_add_tag: any;
  action_delete_tag: any;
  action_tag_prospect: any;

}
class BodyAffaireUpdated {
  action_statut: boolean;
  statut: any;
  list_affaires: any;
  action_add_tag: any;
  action_delete_tag: any;
  action_tag_prospect: any;
}
export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  id: string;
}
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  id: string;
}
var TREE_DATA;
@Injectable({ providedIn: 'root' })
export class ChecklistDatabase {
  dataChange: BehaviorSubject<TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor(private apiStatutService: ApiStatusService,
  ) {
    this.initialize();
  }

  async initialize() {
    //  await this.apiStatutService.GetTreeView()
    //    .pipe(
    //      tap(({ tree }) => {

    //        TREE_DATA = tree;
    //      })
    //    ).toPromise();
    if (TREE_DATA !== null && TREE_DATA !== undefined) {
      const data = this.buildFileTree(TREE_DATA, 0, 2);
    }


    // if(data.length!=0){
    //   this.dataChange.next(data);
    // }
  }

  buildFileTree(obj: { [key: string]: any }, level: number, keyLevels: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value === null || value === undefined) {
        // no action
      } else if (typeof value === 'object' && level < keyLevels) {
        node.children = this.buildFileTree(value, level + 1, keyLevels);
      } else if (typeof value === 'object') {
        node.item = value['etat'];
        node.id = value['id'];
        if (value['child'])
          if (value['child'].length > 0) {
            node.children = this.buildFileTree(value['child'], level + 1, keyLevels);
          }
      } else {
        node.item = value;
        node.id = value;
      }

      return accumulator.concat(node);
    }, []);
  }
}

@Component({
  selector: 'app-actions-groupee',
  templateUrl: './actions-groupee.component.html',
  styleUrls: ['./actions-groupee.component.css']
})
export class ActionsGroupeeComponent implements OnInit {
  tree: tree[] = [];
  TreeData: tree[] = [];
  statuActuel: string[] = [];
   
  deletedlist: any = [];
  type: string = '';
  flatNodeMap: Map<TodoItemFlatNode, TodoItemNode> = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap: Map<TodoItemNode, TodoItemFlatNode> = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(false /* multiple */);

  @Output() onPushFilterStatusActuel: EventEmitter<RendezVous> = new EventEmitter();

  count: number = 0;

  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  activeTags: Tags[] = [];
  loaderActiveTags: boolean = false;
  listeOpp: any;
  listTag: any = [];
  listTagPospect: any = [];
  listeAffaire: any;
  data: any;
  accessList: AccessList;
  act_grp_communication: boolean;
  act_grp_negociateur: boolean;
  act_grp_pubpostage: boolean;
  act_grp_statut: boolean;
  act_grp_tags: boolean;
  saveLoader: boolean;
  currentLanguage: any;
  constructor(private apiStatutService: ApiStatusService,
    private chRef: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    private apiTagsServices: ApiTagsService,
    private apiOpportunitService: ApiOpportunitService,
    private apiAffaireService: ApiAffairesService,
    private apiAuthentificationService: ApiAuthentificationService,
    private translate: TranslateService,

  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  async ngOnInit() {
    this.translate.get('languages').subscribe((object: any) => {
      this.currentLanguage = this.translate.currentLang;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.currentLanguage = this.translate.currentLang;
      });
    });
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.act_grp_communication = this.accessList.act_grp_communication;
      this.act_grp_pubpostage = this.accessList.act_grp_pubpostage;
      this.act_grp_statut = this.accessList.act_grp_statut;
      this.act_grp_tags = this.accessList.act_grp_tags;
   
    });
    if (this.type === 'opp') {
      let body = new ListGet();
      body.list_opportunites = this.listeOpp;
      this.loaderActiveTags = true;

      this.apiTagsServices.GetListCommonTags(body).subscribe(
        (data) => {
          this.activeTags = data;
          this.loaderActiveTags = false;
        },
        () => {
          this.loaderActiveTags = false;
        }
      );
    } else {
      let body = new ListGet();
      body.list_affaires = this.listeAffaire;
      this.loaderActiveTags = true;
      this.apiTagsServices.GetListCommonTags(body).subscribe(
        (data) => {
          this.activeTags = data;
          this.loaderActiveTags = false;
        },
        () => {
          this.loaderActiveTags = false;
        }
      );
    }
    if (this.type === 'affaire') {
      try {
        this.apiStatutService
          .GetTreeViewAffaire()
          .pipe(
            tap(({ tree }) => {
              this.dataSource.data = this.buildFileTree(tree, 0, 2);
            })
          )
          .subscribe();
      } catch (err) {
        console.log(err);
      }
    }
    else {
      try {
        this.apiStatutService
          .GetTreeView()
          .pipe(
            tap(({ tree }) => {
              this.dataSource.data = this.buildFileTree(tree, 0, 2);
            })
          )
          .subscribe();
      } catch (err) {
      }
    }

  }
  addItem(newItem: any) {
    this.listTag = newItem;
  }
  addListTagProspect(list: any) {
    this.listTagPospect = list;

  }
  getLevel = (node: TodoItemFlatNode) => {
    return node.level;
  };

  isExpandable = (node: TodoItemFlatNode) => {
    return node.expandable;
  };

  getChildren = (node: TodoItemNode): Observable<TodoItemNode[]> => {
    return ofObservable(node.children);
  };

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => {
    return _nodeData.expandable;
  };

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => {
    return _nodeData.item === '';
  };

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.id = node.id;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));

    //this.etatRendezVous = this.checklistSelection.selected;
    let selectedElements: string;
    this.checklistSelection.selected.forEach((element) => {
      selectedElements = element.id;
    });
    this.statuActuel[0] = selectedElements;
    return descAllSelected;
  }
  /*   ngOnChanges(changes: SimpleChanges) {
      this.resetStatutForm(changes.Reset.currentValue);
    }
    resetStatutForm(Reset) {
      this.chRef.detectChanges();
      if (Reset === true) {
        this.statuActuel = new RendezVous();
     //   this.etatRendezVous = [];
        this.statuActuel.etat_rdv = [];
        Reset = false;
        this.returnToparent.emit(Reset);
        this.onPushFilterStatusActuel.emit(this.statuActuel);
        this.ngOnInit();
      }
    } */

  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode, event): void {
    if (event.source.checked == true) {
      this.statuActuel[0] = node.id;
    } else {
      const index = this.statuActuel.indexOf(node.id, 0);
      if (index > -1) {
        this.statuActuel.splice(index, 1);
      }
    }

    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }
  unique: any;
  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);

    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  buildFileTree(obj: { [key: string]: any }, level: number, keyLevels: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value === null || value === undefined) {
        // no action
      } else if (typeof value === 'object' && level < keyLevels) {
        node.children = this.buildFileTree(value, level + 1, keyLevels);
      } else if (typeof value === 'object') {
        node.item = value['etat'];
        node.id = value['id'];
        if (value['child'])
          if (value['child'].length > 0) {
            node.children = this.buildFileTree(value['child'], level + 1, keyLevels);
          }
      } else {
        node.item = value;
        node.id = value;
      }

      return accumulator.concat(node);
    }, []);
  }


  removeTag(index: number, data) {
    this.activeTags.splice(index, 1);
    this.deletedlist.push(data)
    //  this.updateTags();
  }
  panelOpenState: boolean = false;
  save(): void {
    this.saveLoader= true 
    if (this.type === 'opp') {
      let bodyOppUpdated = new BodyOppUpdated();
      if (this.statuActuel.length > 0) {
        bodyOppUpdated.action_statut = true;
      } else {
        bodyOppUpdated.action_statut = false;
      }

      bodyOppUpdated.statut = this.statuActuel[0]
      bodyOppUpdated.list_opportunites = this.listeOpp;
      bodyOppUpdated.action_add_tag = this.listTag;
      bodyOppUpdated.action_delete_tag = this.deletedlist;
      bodyOppUpdated.action_tag_prospect = this.listTagPospect;

      this.apiOpportunitService.multipleActionOpportunite(bodyOppUpdated).subscribe((data) => {
        this.saveLoader= false 
        this.activeModal.dismiss(data);
      }, (error) => {
        this.saveLoader= false 
        this.activeModal.dismiss('error');

            });

    } else {

      const bodyAffaireUpdated = new BodyAffaireUpdated();

      if (this.statuActuel.length > 0) {
        bodyAffaireUpdated.action_statut = true;
      } else {
        bodyAffaireUpdated.action_statut = false;
      }

      bodyAffaireUpdated.statut = this.statuActuel[0];
      bodyAffaireUpdated.list_affaires = this.listeAffaire;
      bodyAffaireUpdated.action_add_tag = this.listTag;
      bodyAffaireUpdated.action_delete_tag = this.deletedlist;
      bodyAffaireUpdated.action_tag_prospect = this.listTagPospect;
      this.apiAffaireService.multipleActionAffaire(bodyAffaireUpdated).subscribe((data) => {
        this.saveLoader= false 
        this.activeModal.dismiss(data);
      }, (error) => {
        this.saveLoader= false 
        this.activeModal.dismiss('error');


      })
    }

  }

}
