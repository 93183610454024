<div class="card-historiques  bg-white border-0 p-3 ">
  <div class="card-doc-title ml-3 row" >
    <i class="mr-2 fa-lg fa-solid fa-clock-rotate-left mt-2" [ngClass]="{'ml-2': currentLanguage === 'ar'}"></i>
     {{'languages.opportunite.details.label_history' | translate}}
  </div>
  <mat-form-field class=" p-3 w-100" >
    <input matInput (keyup)="applyFilter($event)" placeholder="{{'languages.miniListing.search' | translate}}"  #input>
  </mat-form-field>
  <div class="mat-elevation-z8"  [ngClass]="{'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar'}">
    <table mat-table  class="w-100" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > {{'languages.opportunite.details.dateAction' | translate}}</th>
        <td mat-cell *matCellDef="let row" > {{row.date_action}} </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > {{'languages.opportunite.label_user' | translate}} </th>
        <td mat-cell *matCellDef="let row" >  {{row.user}} </td>
      </ng-container>
      <ng-container matColumnDef="commentaire">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  >  {{'languages.ticket.commentaireAction' | translate}}</th>
        <td mat-cell *matCellDef="let row" > {{row.commentaire_action}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="7"> {{'languages.affaire.noDataAvailable' | translate}} </td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
