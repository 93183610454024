import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

class Communication {
}


@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {


  communicationList:Communication[]=[
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {}
  ]
  currentLanguage: string;
  constructor(private sharedMenuObserverService:SharedMenuObserverService, private translate: TranslateService
    ) {
      this.currentLanguage = this.translate.currentLang;
     }

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu("Communiqués");
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.currentLanguage = this.translate.currentLang;
      });
    });
    this.translate.get('languages').subscribe((object: any) => {
      this.currentLanguage = this.translate.currentLang;
 
    });
  }

  onClickPhone(){
    console.log('Phone selected');

  }
  onClickStar(){
    console.log('Star selected');
  }
  onClickVue(){
    console.log('Vue selected');

  }
  onClickCopy(){
    console.log('Copy selected');
  }
  onClickPdf(){
    console.log('PDF selected');
  }
  onClickShare(){
    
    console.log('Share selected');
  }
  onClickStatus(){
    console.log('C selected');
  }

  order() {
    alert("Ordered")
  }

}
