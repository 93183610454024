<app-navbar [collapse]="sideBar_status"></app-navbar>
<div class="admin-layout-container" [ngClass]="{'rtl': currentLanguage === 'ar'}">
  <!-- Sidebar for non-Arabic languages -->
  <div *ngIf="currentLanguage !== 'ar'" class="sidebar" [ngClass]="{'collapsed': sideBar_status}">
    <app-sidebar (sidebar_status)="changeSideBarStatus($event)"></app-sidebar>
  </div>

  <!-- Main content area -->
  <div class="main-content" [ngClass]="{'rtl-content': currentLanguage === 'ar'}">
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
    <div class="footer-wrapper">
      <app-footer></app-footer>
    </div>
  </div>

  <!-- Sidebar for Arabic language -->
  <div *ngIf="currentLanguage === 'ar'" class="sidebar" [ngClass]="{'collapsed': sideBar_status}">
    <app-sidebar (sidebar_status)="changeSideBarStatus($event)"></app-sidebar>
  </div>
</div>
<div class="git-issue-bubble">
  <app-gitlab-issue-bulle></app-gitlab-issue-bulle>
</div>
