import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { UtilsService } from './../../../../services/utils/utils.service';
import { environment } from 'src/environments/environment';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

class addGarantieTicket {
  id_ticket: any = '';
  code_garanties: any[] = [];
}

@Component({
  selector: 'app-garantie',
  templateUrl: './garantie.component.html',
  styleUrls: ['./garantie.component.css'],
})
export class GarantieComponent implements OnInit {
  currency: string = environment.currentCurrency;
  franchise: any;
  prime_TTC: any;
  ca: any;
  fromDetails: Boolean = true;
  addGarantieTickets: addGarantieTicket = new addGarantieTicket();
  listeGarantieTicket: any;
  addGarantieLoader: boolean;
  alerts: any;
  currentLanguage: string;
  constructor(
    private apiAffairesService: ApiAffairesService,
    private apiTicketService: ApiTicketsService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private routes: Router
  ) {

  }

  @Input() affaire_id;
  @Input() ticket_id;

  @Input() prospect_id;
  addeditemGarantie: addGarantieTicket = new addGarantieTicket();
  show_loading_list: boolean = false;
  listeGarantieAffaire: any[] = [];
  ListAddGarantie: any[] = [];
  loaderListAddGarantie: boolean = false;
  selectedItems: any[] = [];
  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    console.log(this.currentLanguage);
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.currentLanguage = this.translate.currentLang;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.currentLanguage = this.translate.currentLang;
      });
    });
    this.getTicketGarantie();
  }

  getTicketGarantie() {
    this.show_loading_list = true;
    this.apiTicketService.getGarantiTicket(this.ticket_id, this.affaire_id).subscribe(
      (data) => {
        this.listeGarantieTicket = data;
        this.show_loading_list = false;
      },
      (error) => {
        console.log(error);
        this.show_loading_list = false;
        this.listeGarantieTicket = [];
      }
    );
  }

  getAffaireGarantie() {
    this.loaderListAddGarantie = true;
    this.apiTicketService.getAffaireGarantiTicket(this.affaire_id, true).subscribe(
      (data) => {
        this.listeGarantieAffaire = data.Garanties;
        this.loaderListAddGarantie = false;
      },
      (error) => {
        console.log(error);
        this.loaderListAddGarantie = false;
        this.listeGarantieAffaire = [];
      }
    );
  }

  showModal = false;
  openModalOrPopup() {
    this.showModal = true;
    this.getAffaireGarantie();
  }

  isSelected(index: number): boolean {
    return this.selectedItems.includes(index);
  }

  toggleSelection(index: number): void {
    if (this.isSelected(index)) {
      const selectedIndex = this.selectedItems.indexOf(index);
      this.selectedItems.splice(selectedIndex, 1);
    } else {
      this.selectedItems.push(index);
    }
  }

  addSelectedItems(): void {
    this.addGarantieLoader = true;
    const selectedItemsData = this.selectedItems.map((index) => this.listeGarantieAffaire[index].code);
    this.addGarantieTickets.id_ticket = this.ticket_id;
    this.addGarantieTickets.code_garanties = selectedItemsData;
    this.apiTicketService.addGarantieTicket(this.addGarantieTickets).subscribe(
      (data) => {
        this.addGarantieLoader = false;
        this.showModal = false;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = this.alerts.Successoperation;
        notificationMessage.message = data.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.getTicketGarantie();
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error.messaage;
        this.notificationsService.sendMessage(notificationMessage);
        this.addGarantieLoader = false;
      }
    );

    this.selectedItems = [];
  }

  AddSingleGarantie(selectedItem) {
    this.addeditemGarantie.id_ticket = '';
    this.addeditemGarantie.code_garanties.push(selectedItem.code_garantie);
  }
}
