<div class="row">
    <div class="col-lg-12">
      <div class="navbar-center" class="row">
        <div class="col">
          <div class="row">
            <div style="max-width: 50px" class="col">
              <p>{{'languages.miniListing.Afficher' | translate}}</p>
            </div>
            <div style="max-width: 50px" class="col">
              <select class="select-style" style="margin-top: -6px; padding-top: 8px;">
                <option>15</option>
                <option>30</option>
              </select>
            </div>
            <div class="col">
              <p>Entrées</p>
            </div>
          </div>
  
        </div>
        <div class="col">
          <nav aria-label="Page navigation example">
            <ul class="pagination pagination-circle pg-blue">
              <li class="page-item disabled"><a class="page-link">First</a></li>
              <li class="page-item disabled">
                <a class="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item active"><a class="page-link">1</a></li>
              <li class="page-item"><a class="page-link">2</a></li>
              <li class="page-item"><a class="page-link">3</a></li>
              <li class="page-item"><a class="page-link">4</a></li>
              <li class="page-item"><a class="page-link">5</a></li>
              <li class="page-item">
                <a class="page-link" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link">Last</a></li>
            </ul>
          </nav>
        </div>
        <div class="col">
          <div class="row" style="position: absolute; right: 0px">
            <div class="col">
              <p style="width: 100px;padding-top: 7px;">Aller à la page N°</p>
            </div>
            <div class="col">
              <input type="number" style="margin-left: 4px;" size="1" class="page_input">
            </div>
          </div>
  
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
  
          <div class="row" style="width: 100%">
            <div class="col-4">
              <div class="column" style="display: inline-block;">
                <button class="btn" data-toggle="modal" data-target="#popupNouveauCommunique">
                    <img src="../../../assets/img/opportunites/defaults/add.png"></button>
              </div>
            </div>
            <div class="col-4">
              <div id="search-grp" class="column">
                <img src="../../../assets/img/opportunites/defaults/search.png" alt="">
                <input id="search" class="search" name="" style="border: 2px solid grey;" placeholder="Search...">
              </div>
            </div>
  
            <div id="trie-grp" class="col-4">
              <img src="../../../assets/img/opportunites/defaults/trie.png" alt="">
              <select class="select-style">
                <option>A-Z</option>
                <option>1..9</option>
              </select>
            </div>
          </div>
  
  
        </div>
        <div class="table-responsive" [ngClass]="{'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar'}">
          <table class="table " >
            <thead class="thead-light">
            <tr>
              <th>Actions
              </th>
              <th>Création
              </th>
              <th>Titre
              </th>
              <th>Catégorie
              </th>
              <th>Mode
              </th>
              <th>Niveau d'importance
              </th>
            </tr>
            </thead>
            <tbody>
  
            <tr *ngFor="let communication of communicationList">
              <td class="margin-fix" [ngClass]="{'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar'}">
                <table  style="margin: auto;">
                  <tbody>
                  <tr>
                    <td style="border-top: none; display: inline-block;"><input type="checkbox" name=""></td>
                    <td style="border-top: none; display: inline-block;">
                      <button class="btn" (click)=onClickVue()><img src="../../../assets/img/opportunites/defaults/vue.png" alt=""></button>
                    </td>
                    <td style="border-top: none;">
                      <button class="btn" (click)="onClickShare()" style="display: inline-block;"><img src="../../../assets/img/opportunites/defaults/share.png" alt=""></button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
              <td><p class="td-font">19/10/2020 12:10</p>
                  <p>Jacques Denis</p>
              </td>
              <td class="td-font">Suivi des Parrainages de 2020</td>
              <td>Information Génerale</td>
              <td>Login</td>
              <td class="td-font" >Moyen</td>
            </tr>
            </tbody>
          </table>
        </div>
  
      </div>
      <div class="navbar-center" class="row">
        <div class="col">
          <div class="row">
            <div style="max-width: 50px" class="col">
              <p>{{'languages.miniListing.Afficher' | translate}}</p>
            </div>
            <div style="max-width: 50px" class="col">
              <select class="select-style" style="margin-top: -6px; padding-top: 8px;">
                <option>15</option>
                <option>30</option>
              </select>
            </div>
            <div class="col">
              <p>Entrées</p>
            </div>
          </div>
  
        </div>
        <div class="col">
          <nav aria-label="Page navigation example">
            <ul class="pagination pagination-circle pg-blue">
              <li class="page-item disabled"><a class="page-link">First</a></li>
              <li class="page-item disabled">
                <a class="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item active"><a class="page-link">1</a></li>
              <li class="page-item"><a class="page-link">2</a></li>
              <li class="page-item"><a class="page-link">3</a></li>
              <li class="page-item"><a class="page-link">4</a></li>
              <li class="page-item"><a class="page-link">5</a></li>
              <li class="page-item">
                <a class="page-link" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link">Last</a></li>
            </ul>
          </nav>
        </div>
        <div class="col">
          <div class="row" style="position: absolute; right: 0px">
            <div class="col">
              <p style="width: 100px;padding-top: 7px;">Aller à la page N°</p>
            </div>
            <div class="col">
              <input type="number" style="margin-left: 4px;" size="1" class="page_input">
            </div>
          </div>
  
        </div>
      </div>
    </div>
  
  
    <!-- Modal -->
    <div class="modal fade" id="popupNouveauCommunique"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"  role="document">
        <div class="modal-content" style = "width: 60rem; margin-left: -100px;">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel">Ajouter un nouveau communiqué</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" >
            <div class="container">
                <div class="row" >
                    <div class="col-md-6">
                        <div class="col ">
                            <label>Titre </label><br>
                            <textarea 
                                rows="4"
                                class="form-control" 
                                cols="20" >
                                Saisie un titre...
                            </textarea>
                        </div><br>
                        <div class="col">
                            <div class="row">
                                    <div class="col">
                                      <label>Exige un accusé de reception</label><br>
                                      <input type="radio">
                                     </div>
                                    <div class="col">
                                        <label>Tous </label><br>
                                        <input type="radio">
                                    </div>
                            </div>
                        
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col">
                            <div class="row">
                                    <div class="col">
                                     <label>Catégorie</label><br>
                                      <select class="form-control">
                                         <option >Note de service</option>
                                       </select>
                                     </div>
                                    <div class="col">
                                        <label>Niveau d'importance</label><br>
                                        <select class="form-control">
                                            <option >Moyen</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <label>Mode</label><br>
                                        <select class="form-control">
                                        <option >Login</option>
                                        </select>
                                    </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <label>Groupes</label><br>
                                    <select class="form-control">
                                    <option disabled selected>Séléctionner des groupes</option>
                                    <option >..</option>
                                    </select>
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <label>Utilisateurs</label><br>
                                    <select class="form-control">
                                    <option disabled selected>Séléctionner des Utilisateurs</option>
                                    <option >..</option>
                                    </select>
                                </div>
                            </div>
                        
                        </div>
                    </div>
              </div><br>
              <hr>
              <br>
              <div class="row">
                  <div class="row">
                      <div class="col">

                      </div>
                  </div>
                  <div class="col">

                    <div class="container">
                        <div class="form form-stacked">
                          <div class="form-block">
                            <label class="label" for="board_content">Description</label>
                            <div class="form-controls">
                            <svg width="18px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="highlighter" class="svg-inline--fa fa-highlighter fa-w-17" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 544 512"><path fill="currentColor" d="M0 479.98L99.92 512l35.45-35.45-67.04-67.04L0 479.98zm124.61-240.01a36.592 36.592 0 0 0-10.79 38.1l13.05 42.83-50.93 50.94 96.23 96.23 50.86-50.86 42.74 13.08c13.73 4.2 28.65-.01 38.15-10.78l35.55-41.64-173.34-173.34-41.52 35.44zm403.31-160.7l-63.2-63.2c-20.49-20.49-53.38-21.52-75.12-2.35L190.55 183.68l169.77 169.78L530.27 154.4c19.18-21.74 18.15-54.63-2.35-75.13z"></path></svg>
                              <span class="button" title="Strong " data-button-type="addStrong"><strong>A</strong></span>
                              <span class="button" title="Strong " data-button-type="addStrong"><strong>B</strong></span>
                              <span class="button" title="Emphasis " data-button-type="addEmphasis"><strong><em>I</em></strong></span>
                            </div>
                            <textarea 
                                placeholder="Saisie un texte" 
                                class="textarea-tall" 
                                >
                            </textarea>
                          </div>
                        </div>
                      </div>
                          
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  