<div class="row">
  <div class="position-fixed" style="z-index: 5;" *ngIf="apearFilter">
    <app-filter-echeances [collapseFromParent]="collapseFromParent" (collapseCloseToparent)="getCollapseValue($event)"
      (returnToparent)="resetformFilter($event)" [resetAllFilter]="resetAllFilter" (onPushFilters)="allFilter($event)"
      (onSubmitFilter)="submitFilter($event)">
    </app-filter-echeances>
  </div>
</div>
<div class="row w-100" #myDiv  [ngClass]="{'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar'}">
  <div class="card mb-4 border-0 w-100" style="border-radius: 13px;">
    <div class=" row display-flex justify-content-center align-items-center">
      <div class="col-3">
        <button type="button" class="btn-dt-exporter" (click)="exportDataToCsv()" [disabled]="listEcheancier.length==0"
          [ngStyle]="{'opacity':listEcheancier.length==0? '0.5':'1'}">
          <i class="fa-solid fa-file-excel fa-lg"></i>
          {{'languages.buttons.exporterCSV' | translate}}
        </button>
      </div>

      <div class="col-6 blocDate mt-2">
        <div class="row  w-100  d-flex justify-content-center align-items-center">

          <div class="col-2">
            <img src="assets/icons/calendarListing.svg" class="mr-3" alt="">
          </div>
          <div class="col-8">

            <mat-form-field appearance="fill" class="w-100 fontsDate">
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [(ngModel)]="date_debut" readonly placeholder="Date début  "
                  (click)="openDatePicker()" />
                <input matEndDate [(ngModel)]="date_fin" readonly placeholder="Date fin  " (click)="openDatePicker()" />

              </mat-date-range-input>

              <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="picker">
                <mat-icon matDatepickerToggleIcon>
                  <img src="assets/icons/calendar.svg" alt="calendarIcon">
                </mat-icon>
              </mat-datepicker-toggle>
              <img class="remouveDate" matSuffix (click)="clearDateCreation()" alt="deleteIcon"
                src="assets/icons/delete-left.svg">
              <mat-date-range-picker [calendarHeaderComponent]="rangeDateCreation" #picker></mat-date-range-picker>
            </mat-form-field>

          </div>
          <div class="col-2 w-100  pl-0">
              <button type="button" class="filtrer" (click)="filterWithDate()">
                <img src="assets/icons/filtre.svg" class="mx-1" alt="">
                {{'languages.buttons.filtrer' | translate}}
</button>
          </div>
        </div>
      </div>
      <div class="col-3 w-50 text-right">
        <input class="search" name="search"  [ngClass]="{'pr-5': currentLanguage === 'ar'}" [(ngModel)]="search" (input)="onInputChange($event.target.value)"
          placeholder="{{ 'languages.buttons.rechercher' | translate}}">
        <i class="fa-solid fa-magnifying-glass icon-search fa-md mr-1"></i>
      </div>
    </div>
    <div class="row row-info">

      <div class="card-info-1">
        <p class="p-info-1">{{total_PAYE |currency: currency}} </p>
        <p class="p-info-2">{{'languages.affaire.totalPaid' | translate}}</p>
      </div>
      <div class="card-info-3" (click)="setStatusFilter('PAYE')">
        <p class="p-info-1">{{total_PAYE_month_ago |currency: currency}}</p>
        <p class="p-info-2"> {{'languages.affaire.30paid' | translate}}</p>
      </div>
      <div class="card-info-4" (click)="setStatusFilter('IMPAYE')">
        <p class="p-info-1">{{total_IMPAYE_month_ago |currency: currency}}</p>
        <p class="p-info-2"> {{'languages.affaire.30unpaid' | translate}}</p>
      </div>

      <div class="card-info-2" (click)="setStatusFilter('PLANIFIE')">
        <p class="p-info-1">{{total_PLANIFIE_next_month | currency: currency}} </p>
        <p class="p-info-2">{{'languages.affaire.30planned' | translate}}</p>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> {{'languages.listing.Num' | translate}} </th>
        <td class="text-left" mat-cell *matCellDef="let element ; "> {{element.position}} </td>
      </ng-container>
      <ng-container matColumnDef="nameClient">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{'languages.affaire.label_client' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} {{element.surname}} </td>
      </ng-container>
      <ng-container matColumnDef="motif">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{'languages.affaire.Motif' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.motif}} </td>
      </ng-container>
      <ng-container matColumnDef="date_debut_periode">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{'languages.affaire.startDate' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.date_debut_periode | date: 'dd/MM/yyyy' }} </td>
      </ng-container>
      <ng-container matColumnDef="date_fin_periode">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{'languages.affaire.dateFin' | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.date_fin_periode | date: 'dd/MM/yyyy'}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{'languages.listing.Statut' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">

          <div class="d-flex justify-content-evenly mt-3" id="box">
            <div class="btn-status w-50 " [ngStyle]="{'background-color': element.background ,'color':element.color }">
              {{element.status}}
            </div>

          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="affaire_id">
        <th mat-header-cell *matHeaderCellDef class="text-center"> {{'languages.listing.Affaire' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
          <button class="btn-dt-link">
            <a [routerLink]="['/affaires/details/'+element.affaire_id]" target="_blank">
              <i class="fa-solid fa-link fa-icon-style"></i>
              {{'languages.listing.Affaire' | translate}}
            </a>
          </button>



        </td>
      </ng-container>
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="expand row"
            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            <mat-icon *ngIf="expandedElement !== element"> keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement === element"> keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="row w-100 ">
              <div class="col-2"></div>
              <div class="col w-100 scrollDiv">
                <table class="table  mt-3">
                  <thead class="headtab">
                    <tr>
                      <th><strong> {{'languages.listing.Num' | translate}}</strong></th>
                      <th><strong> {{'languages.affaire.startDate' | translate}}</strong></th>
                      <th><strong> {{'languages.affaire.dateFin' | translate}}</strong></th>
                      <th><strong> {{'languages.affaire.dateprelevement' | translate}}</strong></th>
                      <th><strong> {{'languages.affaire.Montant' | translate}}</strong></th>
                      <th><strong> {{'languages.listing.Statut' | translate}}</strong></th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of element.echeances ; let index = index;">
                    <td>{{index+1}}</td>
                    <td class="custumWidth">{{item.date_debut_periode | date: 'dd/MM/yyyy'}}</td>
                    <td class="custumWidth">{{item.date_fin_periode | date: 'dd/MM/yyyy'}}</td>
                    <td class="custumWidth">{{item.date_prelevement | date: 'dd/MM/yyyy'}}</td>
                    <td class="custumWidth">{{item.montant|currency:currency:'symbol':formatCurrency:'fr-FR'}}</td>
                    <td class="custumWidth">

                      <div class="d-flex justify-content-evenly" id="box">

                        <div class="btn-status w-100 "
                          [ngStyle]="{'background-color': item.background ,'color':item.color }">
                          {{item.status}}
                        </div>
                      </div>
                    </td>


                  </tbody>
                </table>
              </div>

            </div>



          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;  " class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail'] " class="example-detail-row"></tr>
    </table>
    <div *ngIf="dataSource?.length==0 && this.loader">
      <p class="text-center mt-3 noOpp">{{'languages.affaire.noEcheanciers' | translate}}</p>
    </div>
    <div *ngIf="!this.loader">
      <p class="text-center mt-3 d-flex justify-content-center" style="justify-content: center;">
        <mat-spinner [diameter]="40"></mat-spinner>
      </p>
    </div>
    <div class="navbar-center mb-4 row" *ngIf="dataSource?.length >0">
      <div class="col pt-1">
        <div class="row w-50 flex-nowrap">
          <div class="col mt-1">
            <p> {{'languages.miniListing.Afficher' | translate}}</p>
          </div>
          <div class="col mx-1">
            <select [(ngModel)]="limit" (change)="updateListEcheancier()"
              class="bg-white border pt-1 rounded font-weight-bold">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col mt-1 text-nowrap">
            <p>{{'languages.miniListing.pagesPerPage' | translate}}{{totalLength}} {{totalLength}}
              {{'languages.miniListing.element' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-8">
        <nav aria-label="Page navigation example" *ngIf="totalPages>0">
          <ul class="pagination pg-blue">
            <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
              <a class="page-link-symbol">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
              <a class="page-link-symbol-chevron" aria-label="Previous">
                <i class="fa fa-chevron-left "></i>
              </a>
            </li>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+page-3>0" class="page-item">
                <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page - 3}}</a>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+page<totalPages" class="page-item">
                <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                  [ngClass]="{'active-page':page==(i+page)}">
                  {{i + page}}
                </a>
              </li>
            </ng-container>
            <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
            <li class="page-item">
              <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
            </li>
            <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
              <a class="page-link-symbol-chevron" aria-label="Next">
                <i class="fa fa-chevron-right"></i>
              </a>
            </li>
            <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
              <a class="page-link-symbol">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col">
        <div class="row position-absolute flex-nowrap" style="right: 0px">
          <div class="col text-nowrap text-right mt-2">
            <p>{{'languages.listing.label_goto' | translate}}</p>
          </div>
          <div class="col mt-1 ml-2">
            <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
              (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;"
              size="1" class="page_input bg-white border rounded font-weight-bold" [value]="page" max="{{totalPages}}">
          </div>
        </div>
      </div>
    </div>
  </div>

</div>