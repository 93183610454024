<div class="card head">
    <div class="row details-header">
        <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
            <div class="icon-position">
                <i class="fa-solid fa-icon-style fa-lg"  [ngClass]="{'fa-arrow-right': currentLanguage === 'ar','fa-arrow-left': currentLanguage !== 'ar'}"></i>
            </div>
        </button>
    </div>
</div>



<div class="card">
    <div class="row">
        <div class="col-12">
            <agm-map [latitude]="prospect.mainProspect.latitude" [zoom]="1.3" [longitude]="prospect.mainProspect.longitude" style="height: 350px;">
                <agm-marker [latitude]="prospect.mainProspect.latitude" [longitude]="prospect.mainProspect.longitude"></agm-marker>
            </agm-map>
        </div>
    </div>
</div>


<div class="card" id="scrollTop" *ngIf="prospect?.mainProspect !==null">
    <!-- <app-prospect [prospect]="prospect"></app-prospect> -->
    <app-full-prospect-details [person]="true"  [entreprise]="prospect" [typeProspect]="'Prospect'" [typeEntity]="'o'" ></app-full-prospect-details>
</div>

<div class="card">
    <div class="card-doc-title ml-3 row" >
       
      {{ "languages.prospect.oppAffaireOuvertes" | translate}}
      </div>

    <app-fiche-opportunite-prospect   [prospect]="prospect_id"></app-fiche-opportunite-prospect>
</div>

<div class="card">
     <div class="card-doc-title ml-3 row" >
  
     {{ "languages.prospect.Relations" | translate}}
  </div>

    <app-relations [prospect]="prospect_id" [opportunite_id]="opportunite_id" (emitProspectRelation)="getRelationProspect($event)">
    </app-relations>
</div>

<div class="card">

    <div class="card-doc-title ml-3 row" >
    
         {{ "languages.listing.ticketss" | translate}}
      </div>
    <app-tickets-fiche [prospect]="prospect_id"> </app-tickets-fiche>
</div>

<div class="card">
    <div class="card-doc-title ml-3 row" >
        
      {{ "languages.prospect.societes" | translate}}
      </div>

    <app-socicete [prospect]="prospect_id">
    </app-socicete>
</div>
