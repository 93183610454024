<div class="flex-column bg-white rounded-card">
  
    <div class="row d-flex">
      <div class="col-8 justify-content-start mt-4  ">
        <button type="button" class="btn-dt-add " routerLink="fiche/Nouveau"  [attr.data-title]="'languages.affaire.addBanque'">
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
      
      <div class="col-4  d-flex justify-content-end  mt-4" >
        <input class="search" name=""
        [(ngModel)]="filterListBanque.sSearch"
        (input)="onInputChange($event.target.value)"  [ngClass]="{'pr-5': currentLanguage === 'ar'}"
          placeholder="{{'languages.miniListing.search' | translate}}">
          <i aria-hidden="true" class="fa-solid fa-magnifying-glass icon-search fa-md"></i>
      </div>
    </div>
   
    <div class="p-3 mb-3"  [ngClass]="{'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar'}">
      <table id="Table" class="table">
        <thead class="head-table">
          <tr>
            <th>   {{'languages.listing.Num' | translate}}</th>
            <th>   {{'languages.affaire.name' | translate}}</th>
            <th>   {{'languages.affaire.codeSwift' | translate}}</th>
            <th>   {{'languages.affaire.Different' | translate}}</th>
            <th>   {{'languages.listing.actions' | translate}}</th>
          </tr>
        </thead>
        <tbody class="body-table" *ngFor="let banque of listBanque; let index=index;">
          <tr>
            <td>{{index+1}}</td>
            <td>{{banque.nom}}</td>
            <td>{{banque.code_swift}}</td>
            <td>{{banque.code_banque ? banque.code_banque: "-"}}</td>
            <td>
              <button type="button" class="btn-dt-list" routerLink="fiche/{{banque.code_swift}}">
                <img src="/assets/icons/edit-icon.svg">

              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="navbar-center mb-4 row">
      <div class="col pt-1">
        <div class="row w-50 flex-nowrap" >
          <div  class="col mt-1">
            <p>{{'languages.miniListing.Afficher' | translate}}</p>
          </div>
          <div  class="col mx-1">
            <select [(ngModel)]="limit" (change)="updateListBanque()"
            class="bg-white border pt-1 rounded font-weight-bold">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col mt-1">
            <p class="text-nowrap">{{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}} {{'languages.miniListing.element' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-8">
        <nav aria-label="Page navigation example"  *ngIf="totalPages>0">
          <ul class="pagination pg-blue">
            <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
              <a class="page-link-symbol">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2" >
              <a class="page-link-symbol-chevron" aria-label="Previous">
                <i class="fa fa-chevron-left "></i>
              </a>
            </li>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages-3>0" class="page-item">
                <a (click)="changepage(i+pages-3)" class="page-link mt-2 border rounded">{{i + pages - 3}}</a>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages<totalPages" class="page-item">
                <a (click)="changepage(i+pages)" class="page-link mt-2 border rounded"
                [ngClass]="{'active-page':pages==(i+pages)}">
                {{i + pages}}
              </a>
              </li>
            </ng-container>
            <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
            <li class="page-item">
              <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                [ngClass]="{'active-page':pages==totalPages}">{{totalPages}}</a>
            </li>
            <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
              <a class="page-link-symbol-chevron" aria-label="Next">
                <i class="fa fa-chevron-right"></i>
              </a>
            </li>
            <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
              <a class="page-link-symbol">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
          </ul>
        </nav>
    </div>
      <div class="col">
        <div class="row position-absolute flex-nowrap pagination">
          <div class="col text-nowrap text-right mt-2">
            <p >{{'languages.listing.label_goto' | translate}}</p>
          </div>
          <div class="col mt-1 ml-2">
            <input [(ngModel)]="intervale"    size="1"    min="1"  onkeypress="return event.charCode >= 48"  (keyup.enter)="getchangeLimite()"  (change)="getchangeLimite()"  type="number"
            size="1" class="page_input bg-white border rounded font-weight-bold ml-1" [value]="pages" max="{{totalPages}}">
          </div>
        </div>
      </div>
    </div>
  </div>
