import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Process } from '../../../entity/Process';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { ProspectAdd } from '../../../entity/Opportunity/ProspectAdd';
import { OpportunityAdd } from '../../../entity/Opportunity/OpportunityAdd';
import { Campagne } from '../../../entity/Opportunity/Campagne';
import { Details } from '../../../entity/Opportunity/Details';
import { Calendar } from '../../../entity/Opportunity/Calendar';
import { AddOpSharedEntityService } from '../../../shared/SharedInjectors/add-op-shared-entity.service';
import { ApiOpportunitService } from '../../../services/ApiOpportunite/api-opportunite.service';
import { Router } from '@angular/router';
import { ListProspect } from '../../../entity/Prospect';
 
import swal from 'sweetalert2';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import { Store } from '@ngrx/store';
import {
  addOpportuniteStateEnum,
  addOpportuniteStore,
  addOpportuniteTypeProspectEnum,
} from './store/add-opportunite.reducers';
import {
  GetListCampagnes,
  ResetAddOpportunite,
  ResetAddOpportuniteErrorState,
  SaveAddOpportunite,
  SelectCampagne,
  SelectProduct,
  UpdateCalendar,
  UpdateCampagne,
  UpdateDetails,
  UpdateStatus,
} from './store/add-opportunite.actions';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CampagneInfo } from '../../../entity/Opportunity/CampagneInfo';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-opportunite',
  templateUrl: './add-opportunite.component.html',
  styleUrls: ['./add-opportunite.component.css'],
})
export class AddOpportuniteComponent implements OnInit, OnChanges {
   
  info_specifiques;
  info_complementaires;
  opportunity_add: OpportunityAdd = new OpportunityAdd();
  campagnId: string = '';
  step: number = 0;
  country = environment.country;
  listOfProcessClone: Process[] = [];
  listOfProcess: Process[] = [
    new Process('Source', 'active'),
    new Process('Souscripteur principale / Adhérent', 'toDo'),
    new Process('Détails', 'toDo'),
    new Process('Calendrier', 'toDo'),
  ];

  id_opportunite_md5: string = '';
  prosList: any[] = [];
  invalidList: OpportunityAdd;
  campagneCamp: Campagne;
  isbien: boolean = false;
  isperson: boolean = false;
  alerts: any;
  buttons: any;
  currentLanguage: string;
  onNext() {
    const validator = this.ValidateFieldsBeforeStep();
    if (!validator.valid) return;
    if (this.step < this.listOfProcess.length) {
      this.listOfProcess[this.step].class = 'done';
      this.step += 1;
      this.listOfProcess[this.step].class = 'active';
    }
  }

  onPred() {
    if (this.step > 0) {
      this.listOfProcess[this.step].class = 'toDo';
      this.step -= 1;
      this.listOfProcess[this.step].class = 'active';
    }
  }

  constructor(
    private router: Router,
    private store: Store<addOpportuniteStore>,
    private sharedMenuObserverService: SharedMenuObserverService,
    private formlyJsonschema: FormlyJsonschema,
    private addOpSharedEntityService: AddOpSharedEntityService,
    private apiOpportunitService: ApiOpportunitService,
    private store$: Store<addOpportuniteStore>,
    private translate: TranslateService
  ) {}

  opportuniteTranslted: any;
  cycleVieComp$: Observable<any> | null = null;
  cycleVieHorsComp$: Observable<any> | null = null;
  listLignesProduits$: Observable<any> | null = null;
  campagnie$: Observable<any> | null = null;
  infoComplementaireShema$: Observable<any> | null = null;
  infoSpecifiquesShema$: Observable<any> | null = null;
  listOfProcess$: Observable<any> | null = null;
  details$: Observable<any> | null = null;
  listStatusCampagne$: Observable<any> | null = null;
  calendar$: Observable<any> | null = null;
  opportuniteAdd$: Observable<any> | null = null;
  loadState$: Observable<any> | null = null;
  listObjectAssurer$: Observable<any> | null = null;
  listCommerciaux$: Observable<any> | null = null;
  loadedprocess = false;
  opportuniteAdd: OpportunityAdd = new OpportunityAdd();
  listCompagne: any[];
  navbarTxt:string="";
  ngOnInit(): void {
    this.resetAddOpportunite();


    this.translate.onLangChange.subscribe(() => {


      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.opportuniteTranslted = object.opportunite;
        this.listOfProcess[2].categorie=this.buttons.details;
        this.listOfProcess[0].categorie=object.opportunite.source
        this.listOfProcess[1].categorie=object.opportunite.adherent
        this.listOfProcess[3].categorie=object.opportunite.calendrier
        this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.addOpp
        this.currentLanguage = this.translate.currentLang;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });

  });

  this.translate.get('languages').subscribe((object: any) => {
    this.alerts = object.alerts;
    this.buttons = object.buttons;
    this.opportuniteTranslted = object.opportunite;
    this.listOfProcess[2].categorie=this.buttons.details;
    this.listOfProcess[0].categorie=object.opportunite.source
    this.listOfProcess[1].categorie=object.opportunite.adherent
    this.listOfProcess[3].categorie=object.opportunite.calendrier
    this.navbarTxt=object.navbar.Opportunites +' ➜ '+ object.navbar.addOpp
    this.currentLanguage = this.translate.currentLang;
    this.sharedMenuObserverService.updateMenu(this.navbarTxt);
  });

    this.store$.dispatch(GetListCampagnes());
    this.cycleVieComp$ = this.store$.pipe(map((state) => state.addOpportunite.cycleVieComp));
    this.calendar$ = this.store$.pipe(map((state) => state.addOpportunite.calendar));
    this.listCommerciaux$ = this.store$.pipe(map((state) => state.addOpportunite.listCommerciaux));
    this.opportuniteAdd$ = this.store$.pipe(
      map((state) => {
        const opportuniteAdd = new OpportunityAdd();
        opportuniteAdd.campagne = JSON.parse(JSON.stringify(state.addOpportunite.campagne));
        opportuniteAdd.calendar = JSON.parse(JSON.stringify(state.addOpportunite.calendar));
        opportuniteAdd.details = JSON.parse(JSON.stringify(state.addOpportunite.details));
        if (state.addOpportunite.typeProspect === addOpportuniteTypeProspectEnum.PARTICULIER) {
          opportuniteAdd.prospect = state.addOpportunite.prospectParticulier;
        } else {
          opportuniteAdd.prospect = JSON.parse(JSON.stringify(state.addOpportunite.entreprise));
          opportuniteAdd.prospect.typeProspect = JSON.parse(JSON.stringify(state.addOpportunite.typeProspect));
          opportuniteAdd.prospect.listProspects = JSON.parse(
            JSON.stringify(state.addOpportunite.prospectPro.listProspects)
          );
          opportuniteAdd.prospect.mainProspect = JSON.parse(
            JSON.stringify(state.addOpportunite.prospectPro.mainProspect)
          );
        }
        return opportuniteAdd;
      })
    );
    this.opportuniteAdd$.subscribe((data) => (this.opportuniteAdd = data));
    this.listStatusCampagne$ = this.store$.pipe(map((state) => state.addOpportunite.listStatusCampagne));
    this.infoSpecifiquesShema$ = this.store$.pipe(
      map((state) => {
        const infoSpecifiquesSchema: any = state.addOpportunite.infoSpecifiquesSchema;
        if (infoSpecifiquesSchema) {
          /**
           * @param params permet de stocker && convertir schema
           * @param infoSpecifiquesPropreties permet de stocker la conversion des objets
           */
          const params: any = JSON.parse(infoSpecifiquesSchema).schema;
          const infoSpecifiquesPropreties: any[] = [];

          params.properties.forEach((element) => {
            infoSpecifiquesPropreties[element.name] = element;
          });
          params.properties = infoSpecifiquesPropreties;

          const form = this.formlyJsonschema?.toFieldConfig(params);
          return [form];
        } else {
          return null;
        }
      })
    );
    this.infoComplementaireShema$ = this.store$.pipe(
      map((state) => {
        const infoComplementaireSchema: CampagneInfo = state.addOpportunite.infoComplementaireSchema;
        if (infoComplementaireSchema) {
          /**
           * @param params permet de stocker && convertir schema
           * @param infoComplementairePropreties permet de stocker la conversion des objets
           */
          const params: any = JSON.parse(infoComplementaireSchema.form).schema;
          const infoComplementairePropreties: any[] = [];

          params?.properties.forEach((element) => {
            infoComplementairePropreties[element.name] = element;
          });
          if (params?.properties){
            params.properties = infoComplementairePropreties;
          }
          
          const form = this.formlyJsonschema?.toFieldConfig(params);

          return [form];
        } else {
          return null;
        }
      })
    );
    this.campagnie$ = this.store$.pipe(map((state) => state.addOpportunite.campagne));
    this.details$ = this.store$.pipe(map((state) => state.addOpportunite.details));

    this.loadState$ = this.store$.pipe(
      map((state) => {
        return {
          state: state.addOpportunite.loadState,
          errorMessage: state.addOpportunite.errorMessage,
          id_opportunite: state.addOpportunite.id_opportunite,
        };
      })
    );

    this.listLignesProduits$ = this.store$.pipe(map((state) => state.addOpportunite.listLignesProduits));
    this.cycleVieHorsComp$ = this.store$.pipe(map((state) => state.addOpportunite.cycleVieHorsComp));

    this.loadState$.subscribe((data) => {
      if (data.state === addOpportuniteStateEnum.LOADING_STATE) {
        this.showloader();
      }
      if (data.state === addOpportuniteStateEnum.LOADED_STATE) {
        this.hideloader();
      }
      if (data.state === addOpportuniteStateEnum.INSERT_STATE_SUCCESS) {
        this.hideloader();
        this.alertSuccess(this.alerts.addOppSuccess);
        this.id_opportunite_md5 = data.id_opportunite;
        this.store$.dispatch(ResetAddOpportunite());
      }
      if (data.state === addOpportuniteStateEnum.ERROR_STATE) {
        this.hideloader();
        this.alertError(
          this.alerts.addOppProblem,
          this.alerts.pasAutoriseInsertOpp
        );
        this.store$.dispatch(ResetAddOpportuniteErrorState());
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.cycleVieComp$?.currentValue) this.cycleVieComp$ = changes.cycleVieComp$.currentValue;
  }

  alertSuccess(data) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showDenyButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.details,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        denyButtonText: this.buttons.newOpp,
        cancelButtonText: this.buttons.opportunitieslist,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          this.router.navigate(['/opportunities/details/' + this.id_opportunite_md5 + '/prospect']);
        }
        if (result.isDismissed) {
          this.hideloader();
          this.router.navigate(['/opportunities']);
        }
        if (result.isDenied) {
          this.hideloader();
          this.router.navigate(['/opportunities/add']);
          location.reload();
        }
      });
  }

  returnTo() {
    this.router.navigate(['/opportunities']);
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: this.alerts.fillInputs,
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(title, data) {
    swal.fire({
      title: title,
      html: data,
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#ff7220d1',
    });
  }

  setInfoSpecifiques($event) {
    this.store$.dispatch(SelectProduct({ payload: $event }));
  }

  setInfoComplementaires($event: string) {
    this.store$.dispatch(SelectCampagne({ payload: { campagneId: $event } }));
  }

  updateProspect($event: ProspectAdd) {
    if ($event.choice) {
      this.opportunity_add.prospect = $event.entreprise;
    } else {
      this.opportunity_add.prospect = new ListProspect();
      this.opportunity_add.prospect.listProspects = $event.prospect;
      this.opportunity_add.prospect.mainProspect = $event.mainProspect;
    }
    this.addOpSharedEntityService.updateOpportunity(this.opportunity_add);
  }

  updateCampagne($event: Campagne) {
    $event.objectAssurer = $event.objectAssurer.map((item, index) => ({
      ...item,
      index: index,
    }));
    this.store$.dispatch(UpdateCampagne({ payload: $event }));
  }

  updateDetailsInfo($event: Details) {
    const det: Details = Object.assign({}, $event);
    this.store$.dispatch(UpdateDetails({ payload: det }));
  }

  updateCalendar($event: Calendar) {
    this.store$.dispatch(UpdateCalendar({ payload: $event }));
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  onSubmit() {
    if (this.opportuniteFormValid()) {
      this.store$.dispatch(SaveAddOpportunite({ payload: this.opportuniteAdd }));
    }
  }

  private opportuniteFormValidateCampagn() {
    const list_html: string[] = [];
    let valid = true;
    if (
      this.opportuniteAdd.campagne.campagne_id === undefined ||
      this.opportuniteAdd.campagne.campagne_id === null ||
      this.opportuniteAdd.campagne.campagne_id === ''
    ) {
      list_html.push(
        `<li><b> ${this.opportuniteTranslted?.label_campagne} : </b>  ${this.alerts.selectCampagne}</li> `
      );
      valid = false;
    } else {
      if (this.listCompagne.length > 0 && this.opportuniteAdd.campagne.objectAssurer.length === 0) {
        list_html.push(
          `<li><b> ${this.opportuniteTranslted?.label_campagne} :</b>  ${this.alerts.selectObjectAssure}</b></li> `
        );
        valid = false;
      }
    }
    if (this.opportuniteAdd.campagne.choice) {
      if (this.opportuniteAdd.campagne.product_id === '-1') {
        valid = false;
        list_html.push(
          `<li><b>${this.opportuniteTranslted?.label_campagne}:</b> ${this.alerts.SelectProduitSpecific}</li>`
        );
      }
    }
    return { valid: valid, list_html: list_html };
  }

  getObjetAssurer($event) {
    this.listCompagne = $event;
  }

  reloadComponent() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  private opportuniteFormValidateProspect() {
    let valid = true;
    const list_html: string[] = [];
    const form_dict = {
      siren: this.alerts.siren,
      siret: this.alerts.siret,
      tel: this.alerts.phone,
      web: this.alerts.web,
      adresse_mail: this.alerts.adresse_mail,
      mail: this.alerts.email,
      surname: this.alerts.surname,
      name: this.alerts.name,
      naf: this.alerts.naf,
      RS: this.alerts.RS,
      relation: this.alerts.relation,
      email: this.alerts.email,
      securite_sociale: this.alerts.securite_sociale,
      cin: this.alerts.cin,
    };

    if (this.opportuniteAdd.prospect.typeProspect?.toString() === 'entrepise') {
      if (this.opportuniteAdd.prospect.invalidForms) {
        this.opportuniteAdd.prospect.invalidForms.forEach((elem) => {
          valid = false;
          list_html.push(`<li><b>${this.opportuniteTranslted.societe} : </b> ${form_dict[elem]} </li>`);
        });
      } else {
        this.loadState$ = this.store$.pipe(
          map((state) => {
            return {
              state: state.addOpportunite.entreprise.invalidForms.forEach((elem) => {
                valid = false;
                list_html.push(`<li><b>${this.opportuniteTranslted.societe}  : </b> + ${form_dict[elem]} </li>`);
              }),
            };
          })
        );
      }
    }
    if (this.opportuniteAdd.prospect.mainProspect.invalidForms) {
      if (this.opportuniteAdd.prospect.mainProspect.invalidForms !== 'undefined') {
        if (
          this.opportuniteAdd.prospect.mainProspect.name === undefined ||
          this.opportuniteAdd.prospect.mainProspect.name === null ||
          this.opportuniteAdd.prospect.mainProspect.name === ''
        ) {
          valid = false;
          list_html.push(`<li><b>${this.opportuniteTranslted.label_prospect} </b> ${form_dict.name} </li>`);
        }
        if (
          this.opportuniteAdd.prospect.mainProspect.surname === undefined ||
          this.opportuniteAdd.prospect.mainProspect.surname === null ||
          this.opportuniteAdd.prospect.mainProspect.surname === ''
        ) {
          valid = false;
          list_html.push(`<li><b>${this.opportuniteTranslted.label_prospect} </b> ${form_dict.surname} </li>`);
        }
        if (
          this.opportuniteAdd.prospect.mainProspect.invalidForms.includes('adresse_mail') ||
          !this.opportuniteAdd.prospect.mainProspect.surname
        ) {
          valid = false;
          list_html.push(`<li><b>${this.opportuniteTranslted.label_prospect} </b> ${form_dict.adresse_mail} </li>`);
        }
        if (
          this.opportuniteAdd.prospect.mainProspect.securite_sociale &&
          this.opportuniteAdd.prospect.mainProspect.invalidForms.includes('securite_sociale')
        ) {
          valid = false;
          if (this.country === 'TN')
            list_html.push(`<li><b>${this.opportuniteTranslted.label_prospect} </b> ${form_dict.cin} </li>`);
          if (this.country === 'FR')
            list_html.push(
              `<li><b>${this.opportuniteTranslted.label_prospect} </b> ${form_dict.securite_sociale} </li>`
            );
        }
      }
    }
    if (this.opportuniteAdd.prospect.mainProspect.invalidForms === 'undefined') {
      this.loadState$ = this.store$.pipe(
        map((state) => {
          return {
            state: state.addOpportunite.prospectParticulier.mainProspect.invalidForms.forEach((elem) => {
              valid = false;
              list_html.push(`<li><b>${this.opportuniteTranslted.label_prospect} </b> ${form_dict[elem]} </li>`);
            }),
          };
        })
      );
    }

    this.opportuniteAdd.prospect.listProspects.forEach((element, index) => {
      this.prosList[index] = element;
      index++;
    });

    this.prosList.forEach((element) => {
      this.invalidList = element.invalidForms;
    });
    if (this.invalidList) {
      this.opportuniteAdd.prospect.listProspects.forEach((prospect, index) =>
        prospect.invalidForms.forEach((elem) => {
          valid = false;
          list_html.push(
            `<li> <b> ${this.opportuniteTranslted.adherentNum}  ${index + 1} : </b> ${form_dict[elem]} </li>`
          );
        })
      );
    } else {
      this.loadState$ = this.store$.pipe(
        map((state) => {
          return {
            state: state.addOpportunite.prospectParticulier.listProspects.forEach((prospect) =>
              prospect.invalidForms.forEach((elem) => {
                valid = false;
                list_html.push(`<li> <b>${this.opportuniteTranslted.societe} </b> ${form_dict[elem]} </li>`);
              })
            ),
          };
        })
      );
    }

    if (!valid) {
      let strfinal = '<ul class="text-left">';
      list_html.forEach((ele) => (strfinal += ele));
      strfinal += '</ul>';
      this.alertError(`<h3> ${this.alerts.fillObligInputs}</h3>`, strfinal);
    }
    return { valid: valid, list_html: list_html };
  }

  private opportuniteFormValidateCalendar() {
    let valid = true;
    const list_html: string[] = [];
    if (this.opportuniteAdd.calendar.id_type_opportunity === null) {
      valid = false;
      list_html.push(`<li><b>${this.opportuniteTranslted.calendrier}: </b> ${this.alerts.selectTypeMeeting}</li>`);
    }
    if (
      this.opportuniteAdd.calendar.id_type_opportunity !== null &&
      this.opportuniteAdd.calendar.id_type_opportunity !== 4
    ) {
      if (!(this.opportuniteAdd.calendar.dateDebut?.length > 1 || this.opportuniteAdd.calendar.dateFin?.length > 1)) {
        valid = false;
        list_html.push(`<li><b>${this.opportuniteTranslted.calendrier}: </b> ${this.alerts.selectDateMeeting}</li>`);
      }
    }
    if (this.opportuniteAdd.calendar?.affecter === undefined || this.opportuniteAdd.calendar?.affecter === null) {
      valid = false;
      list_html.push(`<li><b>${this.opportuniteTranslted.calendrier}: </b> ${this.alerts.OppToAgent}</li>`);
    }

    return { valid: valid, list_html: list_html };
  }

  private opportuniteFormValidateDetail() {
    let valid = true;
    const list_html: string[] = [];
    if (this.opportuniteAdd.details.etatActuel === 'Assure') {
      if (
        this.opportuniteAdd.details.formContrat.cie_text === '' ||
        this.opportuniteAdd.details.formContrat.cie_text === null ||
        this.opportuniteAdd.details.formContrat.cie_text === undefined
      ) {
        valid = false;
        list_html.push(`<b>${this.alerts.CieOblig}</b> `);
      }
    }
    if (this.opportuniteAdd.campagne.objectAssurer.length === 0 && this.isbien === true) {
      list_html.push(
        `<li><b>${this.buttons.details}</b> ${this.alerts.chooseObjectAssure} </li> `
      );
      valid = false;
    }

    return { valid: valid, list_html: list_html };
  }

  private opportuniteFormValid() {
    let list_html: string[] = [];
    let valid = true;
    const opportuniteFormValidateProspect = this.opportuniteFormValidateProspect();
    if (!opportuniteFormValidateProspect.valid) {
      valid = false;
      list_html = [...list_html, ...opportuniteFormValidateProspect.list_html];
    }
    const opportuniteFormValidateCampagn = this.opportuniteFormValidateCampagn();
    if (!opportuniteFormValidateCampagn.valid) {
      valid = false;
      list_html = [...list_html, ...opportuniteFormValidateCampagn.list_html];
    }
    const opportuniteFormValidateCalendar = this.opportuniteFormValidateCalendar();
    if (!opportuniteFormValidateCalendar.valid) {
      valid = false;
      list_html = [...list_html, ...opportuniteFormValidateCalendar.list_html];
    }
    const opportuniteFormValidateDetail = this.opportuniteFormValidateDetail();
    if (!opportuniteFormValidateDetail.valid) {
      valid = false;
      list_html = [...list_html, ...opportuniteFormValidateDetail.list_html];
    }
    if (!valid) {
      let strfinal = '<ul class="text-left">';
      list_html.forEach((ele) => (strfinal += ele));
      strfinal += '</ul>';
      this.alertError(`<h3> ${this.alerts.fillObligInputs}</h3>`, strfinal);
    }
    return valid;
  }

  ValidateFieldsBeforeStep() {
    let validator = { valid: true, list_html: [] };
    switch (this.step) {
      case 0:
        validator = this.opportuniteFormValidateCampagn();
        break;

      case 1:
        validator = this.opportuniteFormValidateProspect();
        break;
      case 2:
        validator = this.opportuniteFormValidateDetail();
        break;
      case 3:
        validator = this.opportuniteFormValidateCalendar();
        break;
      default:
        break;
    }
    if (!validator.valid) {
      let strfinal = '<ul class="text-left">';
      validator.list_html.forEach((ele) => (strfinal += ele));
      strfinal += '</ul>';
      this.alertError(`<h3> ${this.alerts.fillObligInputs}</h3>`, strfinal);
    }
    return validator;
  }

  navigateToView($event: number) {
    if ($event > this.step) {
      const validator = this.ValidateFieldsBeforeStep();
      if (!validator.valid) return;
    }
    this.step = $event;
    for (let i = 0; i < this.listOfProcess.length; i++) {
      if (i < $event) {
        this.listOfProcess[i].class = 'done';
      } else if (i === $event) {
        this.listOfProcess[i].class = 'active';
      } else if (i > $event) {
        this.listOfProcess[i].class = 'toDo';
      }
    }
  }

  updateStatus($event: any) {
    this.store$.dispatch(UpdateStatus({ payload: $event }));
  }

  resetAddOpportunite() {
    this.store$.dispatch(ResetAddOpportunite());
  }

  compagneupdate($event: Campagne) {

    $event.objectAssurer = $event.objectAssurer.map((item, index) => ({
      ...item,
      index: index,
    }));

    console.log($event);
    this.store$.dispatch(UpdateCampagne({ payload: $event }));
    this.campagneCamp = $event;
  }

  getbien($event) {
    this.isbien = $event;
  }

  getperson($event) {
    this.isperson = $event;
  }
}
