<div class="row" [ngClass]="{'cover': !allowed_to_update}">
    <div class="col card p-3 border-0">
        <div class="col">
            <div class="card-doc-title ml-3 my-3 row">
<i class="fa-solid fa-comment-dots mr-2 mt-2 fa-lg" [ngClass]="{'ml-2': currentLanguage === 'ar'}"></i>
                {{'languages.opportunite.details.commentaire_label' | translate}}
            </div>
            <button type="button" class="add-btn-en btn-add-comment-affaire-position position-absolute " [ngClass]="{'btn-add-comment-affaire-position-Arabic': currentLanguage === 'ar'}"  *ngIf="!addCommentaire" value=" {{'languages.opportunite.details.addComment' | translate}}" (click)="AddComm()">
        <span class="add-bg-btn">
          
          <i class="fa-solid fa-plus"></i>   
             </span>
      </button>
            <ng-container class="p-3" *ngIf="show_loading_add">
                <div class="row mb-3 mt-3">
                    <div class="text-center m-auto">
                        <mat-spinner [diameter]="30"></mat-spinner>
                    </div>
                </div>
            </ng-container>
            <div class="comment scrollbar" [ngClass]="{ 'initial-style': listComments.length < 2 }">
                <div class="row pt-2" *ngIf="listComments.length < 1 && !show_loading_add">
                  <div class="col-12  d-flex justify-content-center">
                    <img src="assets/icons/nodata.svg">
                  </div>
                  <div class="col-12  d-flex justify-content-center">
                    <p class="noData">{{'languages.affaire.noDataAvailable' | translate}} </p>
                  </div>

          </div>
                <div class=" card-commentaire mt-2 mb-2 mr-1" *ngFor="let comment of listComments , let i = index">
                    <div class="row">
                        <div class="text-center mt-3 "  [ngClass]="{'pr-4': currentLanguage === 'ar','pl-4 ': currentLanguage !== 'ar'}" *ngIf="comment.user_image">
                            <img src="{{ comment.user_image }}" class="user-photo" />
                        </div>
                        <div class="text-center mt-3 " [ngClass]="{'pr-4': currentLanguage === 'ar','pl-4 ': currentLanguage !== 'ar'}" *ngIf="comment.user_image == null">
                            <img src="../../../assets/img/user.png" class="user-photo" />
                        </div>
                        <div class="  pt-3 mw-75 ">

                            <div class="col-12  userName">
                                <p class="m-0">
                                     {{ comment.user_name }}</p>
                            </div>


                            <div class="col-12 small greyColor">
                                <p class="dateComment"> <i class="fa-solid fa-calendar mr-1 ml-1"></i>{{ comment.date | date: 'dd/MM/yyyy HH:mm' }}</p>
                            </div>

                         </div>
                    </div>

                        <div *ngIf="comment.commentaire!=null" class="row  p-2">

                            <div class="col-12">
                                <div class="comment-container">

                                  <p [class.collapsed]="isCollapsed[i]"  [class.expanded]="!isCollapsed[i]">
                                    {{ isCollapsed[i] ? (comment.commentaire | slice: 0:280)  + (comment.commentaire.length > 280 ? "...." : "") : comment.commentaire }}
                                  </p>
                                  <button (click)="toggleReadMore(i)"  *ngIf="comment.commentaire.length > 300" class="read">
                                    {{ isCollapsed[i] ? 'Voir plus' : 'Voir moins' }}
                                  </button>
                                </div>
                              </div>




                        </div>
                        <div class="row pb-2">

                            <div class="col-3   "  [ngClass]="{'col-md-4': addCommentaire}" *ngFor="let doc of comment.docs">
                              <div class="fileComment m-2">
                                <a href="{{doc.doc}}" target="_blank"  >
                                    <div class="row imgBox" *ngIf="doc.doc?.includes('.png') || doc.doc?.includes('.jpeg') || doc.doc?.includes('.jpg')">
                                        <img class="imgStyle " src='{{doc.doc}}' alt="">

                                        <div class="ml-2  links">{{expandName(doc.name)}}</div>
                                    </div>


                                    <div  class="row m-2  " *ngIf="!doc.doc?.includes('.png') && !doc.doc?.includes('.jpeg') && !doc.doc?.includes('.jpg')">

                                      <img class=" my-1" src='../../../assets/img/FileIcon.svg'>
                                          <div class="ml-2 mt-2 links"> {{expandName(doc.name)}}</div>
                                      </div>

                                </a>
                              </div>
                            </div>
                        </div>


                </div>
            </div>
        </div>
    </div>
    <div class="col-4 card ml-2 p-3" *ngIf="addCommentaire">
        <button class="icon2-pos text-white w-100 border-0 position-absolute">
      <div class="row" (click)="AddComm()">
<i class="fa-solid fa-circle-xmark p-1  fa fa-lg text-white mt-2 "></i>
        <span class="col mt-1"> {{'languages.opportunite.details.addComment' | translate}}</span>
      </div>
    </button>

        <form [formGroup]="form">
            <div class="modal-body" class="form-group">
                <textarea name="comment" class="col textComment mt-5" placeholder="{{'languages.opportunite.details.enterCommentaire' | translate}}" cols="30" formControlName="comment" rows="5"></textarea>
                <div class="mb-3 col-md-12">
                    <!-- <label for="formFile" class="form-label">Joindre des fichiers</label> -->
                    <!-- <input (change)="this.onFilechange($event)" class="form-control" multiple type="file" id="formFile"> -->
                    <div class="form-group mt-1">
                        <label for="fileField" class="attachment">
              <div class="row btn-file mt-1">
                <div class="btn-file__preview mt-1"></div>
                <div class="btn-file__actions mt-1">
                  <div class="btn-file__actions__item mt-1 col-xs-12 text-center " id="file-upload-container"
                  (dragover)="onDragOver($event)" (drop)="onDrop($event)">
                    <div class="btn-file__actions__item--shadow mt-1">
                      <i class="fa-solid fa-upload fa-icon-size"></i>
                      <div class="  mt-2"></div>
                      <p class="import">{{'languages.opportunite.details.selectFile' | translate}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <input (change)="onFilechange($event)"  accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .zip, .odt, .txt"  name="file" type="file" id="fileField">
            </label>
            <p *ngIf="showTypeDoc" class="errorMsg  mt-3">
<i class="fa-solid fa-circle-exclamation mr-2"></i>
              
              {{'languages.opportunite.details.typeFile' | translate}}  pdf, jpg, png, jpeg, doc, docx, ppt, pptx, xls, xlsx, zip, odt, txt
          </p>
                    </div>
                    <div class="row " [ngClass]="{'files  scrollbar': fileList.length>1 }" >
                        <div class="col-12 fileComment d-flex  align-items-center mb-2 py-2 " *ngFor="let file of fileList">
                          <div class="col-2">
                            <img *ngIf="file.types=='image'" class="imgStyle" src='{{file.path}}' alt="">
                            <img *ngIf="file.types=='file'" src='../../../assets/img/FileIcon.svg'>
                        </div>
                        <span class="file-name col-8 links">{{expandName(file.name)}}  </span>
                            <button  type="button" (click)="removefile(file)" class=" col-2 deleteFile">
                              <img  src='../../../assets/icons/deleteIcon.svg'>
                              </button>

                            


                           

                        </div>
                    </div>
                    <div *ngIf="loading" class="row">
                      <div class="text-center m-auto">
                          <mat-spinner [diameter]="15"></mat-spinner>
                      </div>
                  </div>
                </div>
            </div>

            <div class="d-flex justify-content-center">
              <button type="button" class="btn-dt-ajouter mr-2 ml-2" [disabled]="!this.form.value.comment && this.fileList.length===0" [ngClass]="{'disable-btn':!this.form.value.comment && this.fileList.length===0}" (click)="onSubmit()" type="submit">
<i class="fa-solid fa-circle-plus fa fa-lg"></i> {{'languages.buttons.ajouter' | translate}}
              </button> 
             
                  <button type="button" class="btn-dt-fermer  ml-2"  aria-label="Close"
                  (click)="AddComm()">
<i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1 ml-1"></i>
            {{'languages.buttons.annuler' | translate}}
        </button> 
                
            </div>
            <p class="info mt-3">
<i class="fa-solid fa-circle-info mr-2 fa-lg"></i>
                 {{'languages.opportunite.details.donneSensible' | translate}}
                
            </p>
        </form>
    </div>
</div>
