import { HistoryServiceTicket } from 'src/app/pages/tickets-and-support/history.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ListStatut } from 'src/app/entity/Affaires/Tickets';
import { UserList } from 'src/app/entity/User/UserList';
import { ListCategorieTickets } from 'src/app/entity/Affaires/ListCategorieTickets';
import { AddTicket } from 'src/app/entity/TicketsAndSupport/AddTicket';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { map, tap } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import swal from 'sweetalert2';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { InformationTicket } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { Observable } from 'rxjs';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
import { AngularEditorConfig } from '../angular-editor/config';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { HistoryService } from 'src/app/pages/affaires/affaire-details/history.service';
import { NotificationsNavbarService } from './../../services/notifications-navbar.service';

@Component({
  selector: 'app-add-tickets',
  templateUrl: './add-tickets.component.html',
  styleUrls: ['./add-tickets.component.css'],
})
export class AddTicketsComponent implements OnInit {
  @ViewChild('closeModal') private closeModal: ElementRef;
  @ViewChild('myForm') mytemplateForm: NgForm;
  @Output() newItemEvent = new EventEmitter<any>();
  @Input() addeditemGarantie;
  @Input() id;
  @Input() idTicket;
  @Input() type;
  @Input() prospect;
  @Input() fromDetails;
  @Input() sousTicket;
  public Editor = ClassicEditor;
  // public config = {
  //   toolbar: [
  //     'heading',
  //     '|',
  //     'bold',
  //     'italic',
  //     'undo',
  //     'redo',
  //     'blockQuote',
  //     '|',
  //     'numberedList',
  //     'bulletedList',
  //     'mediaEmbed',
  //     '|',
  //     'insertTable',
  //     'tableColumn',
  //     'tableRow',
  //     'mergeTableCells',
  //   ],
  // };

  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '5rem',
    maxHeight: '15rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: true,
    toolbarPosition: 'bottom',
    defaultFontName: 'Comic Sans MS',
    defaultFontSize: '5',
    defaultParagraphSeparator: 'p',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  show_loading_add: boolean = false;
  formTicket: FormGroup;
  listCategorieTickets: ListCategorieTickets[] = [];
  list_statut: ListStatut[] = [];
  userList: UserList[] = [];
  isLoadingResult: boolean = true;
  file: File = null;
  datelimit: string = '';
  show_user_erreur: string = '';
  sujet: string = '';
  affecteUser: any;
  fileList: File[] = [];
  showNameFile: string;
  index: number = 0;
  user_affecte: any;
  addTicket: AddTicket = new AddTicket();
  id_categorie: string;
  libelle_categorie: string = '';
  delai_traitement: any;
  informationTicket = new InformationTicket();
  isDisabled: boolean;
  date: Date;
  show_loading: boolean = false;
  list_invalid_string: string = '';
  list_invalid: any;
  show_loading_Categorie: boolean = false;
  listUserFilter: Observable<UserList[]>;
  listUser: UserList[] = [];
  affecterAFormControl: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  minDate = new Date();
  statusrefresh: boolean = false;
  alerts: any;
  buttons: any;
  ticket: any;
  categorieList: any;
  currentLanguage: string;
  constructor(
    private apiAffairesService: ApiAffairesService,
    private apiTicketService: ApiTicketsService,
    private apiUsersService: ApiUsersService,
    private router: Router,
    private routes: Router,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private HistoryService: HistoryService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private NotificationsNavbarService: NotificationsNavbarService,
    private HistoryServiceTicket: HistoryServiceTicket
  ) {}

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.ticket = object.ticket;
      this.currentLanguage = this.translate.currentLang;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.ticket = object.ticket;
        this.currentLanguage = this.translate.currentLang;
      });
    });
    this.getListCategorieTicket();
    this.getStatusTicket();
    this.getUserList();
    this.listUserFilter = this.affecterAFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.nom + ' ' + value.prenom)),
      map((value) => {
        return this._affecterAfilter(value);
      })
    );
    this.route.queryParams.subscribe((params) => {
      if (params.subject) {
        this.addTicket.title = params.subject;
        this.addTicket.commentaire =
          this.alerts.courrierEnvoyeDe + params.from + this.alerts.toa + params.to + this.alerts.toDate + params.time;
      }
    });
  }

  private _affecterAfilter(value: string): UserList[] {
    const filterValue = value.toLowerCase();
    return this.listUser.filter((option) => (option.nom + ' ' + option.prenom).toLowerCase().indexOf(filterValue) > -1);
  }

  getUserList() {
    this.apiUsersService.getAllUsers().subscribe((data: UserList[]) => {
      this.listUser = data.filter((item) => item.nom !== null && item.prenom !== null);
      this.isLoadingResult = false;
    });
  }

  public displayContactFn(contact?: UserList): string | undefined {
    this.user_affecte = contact?.id;
    return contact ? contact.nom + ' ' + contact.prenom : null;
  }

  getParentCategories(categoryId: string, data: any[]): string {
    const category = this.findCategory(categoryId, data);
    if (!category) return '';

    const hierarchy: string[] = [];

    let currentCategory = category;
    while (currentCategory && currentCategory.libelle_cat_parent) {
      hierarchy.unshift(currentCategory.libelle_cat_parent);
      currentCategory = this.findCategory(currentCategory.id_parent, data);
    }

    // If there is only one element in the hierarchy array, return just the last category
    if (hierarchy.length === 0) {
      return category.libelle;
    } else {
      // Otherwise, join the hierarchy array with ' ➜ ' separator and append the last category
      return hierarchy.join(' ➜ ') + ' ➜ ' + category.libelle;
    }
  }

  private findCategory(categoryId: string, data: any[]): any {
    const stack = [...data];
    while (stack.length) {
      const current = stack.pop();
      if (current.id === categoryId) return current;
      if (current.child) stack.push(...current.child);
    }
    return null;
  }

  setCategorieValue(id: string, libelle: string, delai_traitement: any, affect_a: any, libelle_cat_parent) {
    this.id_categorie = id;
    this.libelle_categorie = libelle;
    this.delai_traitement = delai_traitement;
    const hierarchyTitle = this.getParentCategories(id, this.categorieList);
    this.addTicket.title = hierarchyTitle;

    if (affect_a) {
      const defaultUser = this.listUser.find((user) => user.id === affect_a);
      if (defaultUser.id) {
        this.affecteUser = defaultUser;
        this.affecterAFormControl.setValue(defaultUser);
      }
    } else {
      this.affecterAFormControl.setValue('');
    }

    if (this.delai_traitement === null) {
      this.isDisabled = false;
      this.addTicket.date_limite = this.delai_traitement;
    } else {
      this.isDisabled = true;
      this.date = new Date();
      const value_date_limite = this.date.setDate(this.date.getDate() + this.delai_traitement);
      this.addTicket.date_limite = this.datePipe.transform(value_date_limite, 'yyyy-MM-dd');
    }
  }

  getListCategorieTicket() {
    this.apiTicketService
      .listCatTicketGet()
      .pipe(
        tap(({ categories_ticket }) => {
          this.categorieList = categories_ticket;
          const list: any[] = categories_ticket.filter((elem) => elem.id_parent == null);
          list.forEach((element) => {
            element.childs = this.getChildren(categories_ticket, element);
          });
          this.listCategorieTickets = list;
          this.show_loading_Categorie = true;
        })
      )
      .subscribe();
  }

  getStatusTicket() {
    this.apiTicketService
      .GetStatusTicket()
      .pipe(
        tap(({ list_statut }) => {
          this.list_statut = list_statut;
        })
      )
      .subscribe();
  }

  getFiles(event: any) {
    this.file = event.target.files[0];
    this.fileList[this.index] = this.file;
    this.index++;
  }

  dropFile(index: number) {
    this.fileList.splice(index, 1);
    this.index--;
  }

  alertSuccess(data, idTicket) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        denyButtonText: this.buttons.newTicket,
        denyButtonColor: '833626',
        cancelButtonText: this.buttons.listTickets,
        confirmButtonText: this.buttons.updateTicket,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.routes.navigate(['/ticketsandsupport/details/' + idTicket]);
          this.closeModal.nativeElement.click();
          this.reset(this.mytemplateForm);
        }
        if (result.isDismissed) {
          if (result.dismiss === swal.DismissReason.close) {
            if (this.closeModal) this.closeModal.nativeElement.click();
            this.reset(this.mytemplateForm);
          } else {
            this.routes.navigate(['/ticketsandsupport']);
            if (this.closeModal) this.closeModal.nativeElement.click();
            this.reset(this.mytemplateForm);
          }
        }
        if (result.isDenied) {
          if (this.closeModal) this.closeModal.nativeElement.click();
          this.reset(this.mytemplateForm);
        }
      });
  }

  retourToList() {
    this.router.navigate(['/ticketsandsupport']);
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: this.alerts.badHappened,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  getName(name) {
    let name_returned = '';
    switch (name) {
      case 'sujetT':
        name_returned = this.ticket.sujetTicket;
        break;

      case 'affecte':
        name_returned = this.ticket.userAffecte;
        break;

      case 'statut':
        name_returned = this.ticket.statusticket;
        break;

      case 'date_Limite':
        name_returned = this.ticket.limitTicket;
        break;

      default:
        name_returned = '';
    }
    return name_returned;
  }

  alertWarning(data) {
    this.list_invalid_string = '';
    if (this.libelle_categorie === '') {
      this.list_invalid_string = `<li style="list-style-type: none; font-size: 14px">${this.ticket.Category}</li>`;
    }
    this.list_invalid.forEach((element) => {
      if (typeof element.name === 'string')
        this.list_invalid_string =
          this.list_invalid_string +
          '<li style="list-style-type: none; font-size: 14px">' +
          this.getName(element.name) +
          '</li>';
    });
    swal.fire({
      title: data,
      icon: 'warning',
      html:
        `<b>${this.alerts.fillInputs}</b><br><br>` + '<ul style="padding:0px">' + this.list_invalid_string + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }

  onSubmit(valid, directive) {
    this.list_invalid = directive.filter((element) => element.control.status === 'INVALID');
    if (!valid || this.libelle_categorie === '' || this.affecterAFormControl.invalid) {
      this.alertWarning(this.alerts.formulaireinvalide);
    } else {
      this.show_loading = true;
      const formData: FormData = new FormData();
      formData.append('statut', this.addTicket.statut);
      formData.append('commentaire', this.addTicket.commentaire);
      formData.append('affect', this.affecteUser.id);
      formData.append('categorie', this.id_categorie);
      if (this.addTicket.date_limite) {
        this.datelimit = this.datePipe.transform(this.addTicket.date_limite, 'yyyy-MM-dd');
      } else {
        this.datelimit = '';
      }
      formData.append('date_limit', this.datelimit);
      formData.append('title', this.addTicket.title);
      // formData.append('breadcrumb_cat_ticket', this.libelle_categorie);
      this.fileList.forEach((file) => formData.append('file[]', file));
      if (this.type === 'a') {
        if (this.sousTicket) {
          this.apiTicketService
            .AjouterTicket(formData, this.id, '', this.prospect, this.type, this.libelle_categorie, this.idTicket)
            .subscribe(
              (response) => {
                this.apiAffairesService.pushTickets('tickets');

                if (this.addeditemGarantie?.code_garanties.length > 0) {
                  this.addeditemGarantie.id_ticket = response.id;
                  this.apiTicketService.addGarantieTicket(this.addeditemGarantie).subscribe(
                    (data) => {
                      this.show_loading = false;
                      const notificationMessage = new NotificationMessage();
                      notificationMessage.type = NotificationType.success;
                      notificationMessage.title = this.alerts.Successoperation;
                      notificationMessage.message = data.message;
                      this.notificationsService.sendMessage(notificationMessage);
                      this.closeModal.nativeElement.click();
                      this.HistoryServiceTicket.reloadSousTicket(true);

                      this.NotificationsNavbarService.triggerNotificationUpdate();
                    },
                    (error) => {
                      this.show_loading = false;
                      const notificationMessage = new NotificationMessage();
                      notificationMessage.type = NotificationType.error;
                      notificationMessage.title = this.alerts.Operationechoue;
                      notificationMessage.message = error.message;
                      this.notificationsService.sendMessage(notificationMessage);
                    }
                  );
                } else {
                  this.show_loading = false;
                  this.HistoryServiceTicket.reloadSousTicket(true);
                  const notificationMessage = new NotificationMessage();
                  notificationMessage.type = NotificationType.success;
                  notificationMessage.title = this.alerts.Successoperation;
                  notificationMessage.message = response.messaage;
                  this.notificationsService.sendMessage(notificationMessage);
                  this.closeModal.nativeElement.click();
                  this.show_loading = false;
                  this.closeModal.nativeElement.click();
                  this.NotificationsNavbarService.triggerNotificationUpdate();
                }
              },
              (err) => {
                this.alertError(err.messaage);
                this.show_loading = false;
              }
            );
        } else {
          this.apiTicketService
            .AjouterTicket(formData, this.id, '', this.prospect, this.type, this.libelle_categorie, null)
            .subscribe(
              (response) => {
                this.apiAffairesService.pushTickets('tickets');
                const notificationMessage = new NotificationMessage();
                notificationMessage.type = NotificationType.success;
                notificationMessage.title = this.alerts.Successoperation;
                notificationMessage.message = response.messaage;
                this.notificationsService.sendMessage(notificationMessage);
                this.closeModal.nativeElement.click();
                this.HistoryService.sendFetchDataSignal(true);
                this.NotificationsNavbarService.triggerNotificationUpdate();
                this.show_loading = false;
              },
              (err) => {
                const notificationMessage = new NotificationMessage();
                notificationMessage.type = NotificationType.error;
                notificationMessage.title = this.alerts.Operationechoue;

                notificationMessage.message = err.messaage;
                this.notificationsService.sendMessage(notificationMessage);
                this.show_loading = false;
              }
            );
        }
      } else if (this.type === 'o') {
        if (this.sousTicket) {
          this.apiTicketService
            .AjouterTicket(formData, '', this.id, this.prospect, this.type, this.libelle_categorie, this.idTicket)
            .subscribe(
              (response) => {
                this.apiAffairesService.pushTickets('tickets');

                this.show_loading = false;
                this.HistoryServiceTicket.reloadSousTicket(true);
                const notificationMessage = new NotificationMessage();
                notificationMessage.type = NotificationType.success;
                notificationMessage.title = this.alerts.Successoperation;
                notificationMessage.message = response.messaage;
                this.notificationsService.sendMessage(notificationMessage);
                this.closeModal.nativeElement.click();
                this.show_loading = false;
                this.closeModal.nativeElement.click();
                this.NotificationsNavbarService.triggerNotificationUpdate();
              },
              (err) => {
                this.alertError(err.messaage);
                this.show_loading = false;
              }
            );
        } else {
          this.apiTicketService
            .AjouterTicket(formData, '', this.id, this.prospect, this.type, this.libelle_categorie, this.idTicket)
            .subscribe(
              (response) => {
                this.apiAffairesService.pushTickets('tickets');
                this.alertSuccess(response.messaage, response.id);
                this.show_loading = false;
                this.statusrefresh = true;
                this.newItemEvent.emit(this.statusrefresh);
                this.NotificationsNavbarService.triggerNotificationUpdate();
              },
              (err) => {
                this.alertError(err.messaage);
                this.show_loading = false;
              }
            );
        }
      } else {
        if (this.sousTicket) {
          this.apiTicketService
            .AjouterTicket(formData, '', '', this.prospect, this.type, this.libelle_categorie, this.idTicket)
            .subscribe(
              (response) => {
                this.apiAffairesService.pushTickets('tickets');

                this.show_loading = false;
                this.HistoryServiceTicket.reloadSousTicket(true);
                const notificationMessage = new NotificationMessage();
                notificationMessage.type = NotificationType.success;
                notificationMessage.title = this.alerts.Successoperation;
                notificationMessage.message = response.messaage;
                this.notificationsService.sendMessage(notificationMessage);
                this.closeModal.nativeElement.click();
                this.show_loading = false;
                this.closeModal.nativeElement.click();
                this.NotificationsNavbarService.triggerNotificationUpdate();
              },
              (err) => {
                this.alertError(err.messaage);
                this.show_loading = false;
              }
            );
        } else {
          this.apiTicketService
            .AjouterTicket(formData, '', '', this.prospect, this.type, this.libelle_categorie, null)
            .subscribe(
              (response) => {
                this.apiAffairesService.pushTickets('tickets');
                this.alertSuccess(response.messaage, response.id);
                this.show_loading = false;
                this.NotificationsNavbarService.triggerNotificationUpdate();
              },
              (err) => {
                this.alertError(err.messaage);
                this.show_loading = false;
              }
            );
        }
      }
    }
  }

  reset(myForm?) {
    this.addTicket = new AddTicket();
    this.fileList = [];
    this.setCategorieValue('', '', '', '', '');
    this.affecteUser = '';
    myForm.reset('');
    this.affecterAFormControl.setValue('');
    this.affecterAFormControl.setErrors(null);
    this.isDisabled = false;
  }

  showSubMenu($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.closeAllDropDownElements($event);
    const parentNode = $event.target.parentNode;
    const listDropDowns = parentNode.getElementsByClassName('dropdown-menu');
    const elem = listDropDowns[0];
    elem.classList.toggle('show');
  }

  private closeAllDropDownElements($event) {
    const parentNode = $event.target.parentNode;
    const listDropDownss = parentNode.parentNode.getElementsByClassName('dropdown-menu');
    for (let i = 0; i < listDropDownss.length; i++) {
      listDropDownss[i].classList.remove('show');
    }
  }

  getChildren(array, parent) {
    const list = array.filter((ele) => ele.id_parent === parent.id);
    if (list.length > 0)
      list.forEach((element) => {
        element.childs = this.getChildren(array, element);
      });
    return list;
  }

  expandFileName(name: string): string {
    if (name?.length < 12) {
      return name;
    } else {
      return name?.substring(0, 7) + '...' + name?.substring(name?.length - 6, name?.length);
    }
  }

  expandName(name: string): string {
    if (name?.length < 25) {
      return name;
    } else {
      return name?.substring(0, 25) + '...';
    }
  }
}
