import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
  OnChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { Ticket } from 'src/app/entity/TicketsAndSupport/FilterTicket';
import { DatePipe } from '@angular/common';
 
import { Observable, Subscription, forkJoin } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { catchError } from 'rxjs/operators';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';

class TreeNode {
  libelle: string;
  id: string;
  child?: TreeNode[]; // eslint-disable-line
}

class FlatNode {
  expandable: boolean;
  libelle: string;
  level: number;
}
@Component({
  selector: 'app-filter-ticket-ticket',
  templateUrl: './filter-ticket-ticket.component.html',
  styleUrls: ['./filter-ticket-ticket.component.css'],
})
export class FilterTicketTicketComponent implements OnInit, OnChanges, OnDestroy {
  readonly rangeDateCreation = RangeDateComponent;

  readonly rangeDateTraitement = RangeDateComponent;

  readonly rangeDateLimit = RangeDateComponent;

   
  status: any[] = [];
  @Output() onPushFilterTicket: EventEmitter<Ticket> = new EventEmitter();
  ticket: Ticket = new Ticket();
  selected2: string;
  hello: string;
  date_debut_traitement: string = '';
  date_fin_traitement: string = '';
  date_debut_creation: string = '';
  date_fin_creation: string = '';
  date_debut_limit: string = '';
  date_fin_limit: string = '';
  listeunsubscribe: Subscription[] = [];
  filtre: any;

  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  treeData: TreeNode[] = [];
  @ViewChild('pickerDC') pickerDC: MatDateRangePicker<any>;
  @ViewChild('pickerDT') pickerDT: MatDateRangePicker<any>;
  @ViewChild('pickerDL') pickerDL: MatDateRangePicker<any>;

  constructor(private apiTickets: ApiTicketsService, private datePipe: DatePipe, private chRef: ChangeDetectorRef) {
    this.apiTickets.listCatTicketGet().subscribe((data) => {
      data.categories_ticket.forEach((element) => {
        const node: TreeNode = new TreeNode();
        node.libelle = element.libelle;
        node.id = element.id;
        if (element.child) node.child = element.child;

        this.treeData.push(node);
        this.filtre = localStorage.getItem('filtreTicket');
        this.filtre = JSON.parse(this.filtre);
        if (this.filtre) {
          this.ticket = this.filtre.ticket;
          this.checkedNodeIds = this.ticket.categorie;
        }
      });
      this.dataSource.data = this.treeData;
    });
  }

  openDateCreation() {
    if (this.pickerDC) {
      this.pickerDC.open();
    }
  }

  openDateTraitement() {
    if (this.pickerDT) {
      this.pickerDT.open();
    }
  }

  openDateLimit() {
    if (this.pickerDL) {
      this.pickerDL.open();
    }
  }

  clearDateLimit() {
    this.ticket.date_debut_limit = null;
    this.ticket.date_fin_limit = null;
    this.date_debut_limit = null;
    this.date_fin_limit = null;
    this.EmitFilterNumTickets();
  }

  clearDateTraitement() {
    this.ticket.date_debut_traitement = null;
    this.ticket.date_fin_traitement = null;
    this.date_debut_traitement = null;
    this.date_fin_traitement = null;
    this.EmitFilterNumTickets();
  }

  clearDateCreation() {
    this.ticket.date_debut_creation = null;
    this.ticket.date_fin_creation = null;
    this.date_debut_creation = null;
    this.date_fin_creation = null;
    this.EmitFilterNumTickets();
  }

  ngOnDestroy(): void {
    this.listeunsubscribe.forEach((element) => {
      element.unsubscribe();
    });
    localStorage.removeItem('filtreTicket');
  }

  ngOnInit(): void {
    this.GetStatusTicket();
    this.filtre = localStorage.getItem('filtreTicket');
    this.filtre = JSON.parse(this.filtre);
    if (this.filtre) {
      this.apiTickets.listCatTicketGet().subscribe((data) => {
        data.categories_ticket.forEach((element) => {
          const node: TreeNode = new TreeNode();
          node.libelle = element.libelle;
          node.id = element.id;
          if (element.child) node.child = element.child;

          this.treeData.push(node);
          this.filtre = localStorage.getItem('filtreTicket');
          this.filtre = JSON.parse(this.filtre);
          if (this.filtre) {
            this.ticket = this.filtre.ticket;
            this.checkedNodeIds = this.ticket.categorie;
          }
        });
        this.dataSource.data = this.treeData;
      });

      this.ticket = this.filtre.ticket;
      this.date_debut_creation = this.datePipe.transform(this.ticket.date_debut_creation, 'yyyy-MM-dd');

      this.date_fin_creation = this.datePipe.transform(this.ticket.date_fin_creation, 'yyyy-MM-dd');
      this.date_debut_traitement = this.datePipe.transform(this.ticket.date_debut_traitement, 'yyyy-MM-dd');
      this.date_fin_traitement = this.datePipe.transform(this.ticket.date_fin_traitement, 'yyyy-MM-dd');
      this.date_debut_limit = this.datePipe.transform(this.ticket.date_debut_limit, 'yyyy-MM-dd');
      this.date_fin_limit = this.datePipe.transform(this.ticket.date_fin_limit, 'yyyy-MM-dd');
      const allApi: Observable<any>[] = [this.apiTickets.listCatTicketGet(), this.apiTickets.GetStatusTicket()];
      forkJoin(allApi)
        .pipe(
          catchError((error) => {
            console.error('Error:', error);
            return [];
          })
        )
        .subscribe((responses) => {
          this.EmitFilterNumTickets();
        });
    }

    // this.listCatTicketGet();
  }

  GetStatusTicket() {
    const unsubscribeGetStatusTicket = this.apiTickets.GetStatusTicket().subscribe(
      (data) => {
        this.status = data.list_statut;
        if (this.filtre) {
          if (this.ticket.recheche_status.length > 0) {
            this.status
              .filter((statut) => this.ticket.recheche_status.includes(statut.id))
              .map((statutAff) => {
                this.selected2 = statutAff.id;
                return this.selected2;
              });
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
    this.listeunsubscribe.push(unsubscribeGetStatusTicket);
  }

  listCatTicketGet() {
    const unsubscribeListCatTicketGet = this.apiTickets.listCatTicketGet().subscribe(
      (data) => {
        this.dataSource.data = data.categories_ticket;
      },
      (err) => {
        console.error(err);
      }
    );
    this.listeunsubscribe.push(unsubscribeListCatTicketGet);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetticketForm(changes.Reset.currentValue);
  }

  resetticketForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ticket.date_debut_creation = this.date_debut_creation = '';
      this.ticket.date_fin_creation = this.date_fin_creation = '';
      this.ticket.date_debut_traitement = this.date_debut_traitement = '';
      this.ticket.date_fin_traitement = this.date_fin_traitement = '';
      this.ticket.date_debut_limit = this.date_debut_limit = '';
      this.ticket.date_fin_limit = this.date_fin_limit = '';
      this.ticket.recheche_num_ticket = '';
      this.ticket.recheche_sujet_ticket = '';
      this.ticket.recheche_status = [];
      this.ticket.categorie = [];
      this.checkedNodeIds = [];
      this.selected2 = '';
      this.checkedNodeIds = [];
      Reset = false;
      const checkbox = document.getElementById(`checkbox`) as HTMLInputElement;
      if (checkbox) {
        checkbox.checked = !checkbox.checked;
      }
      this.returnToparent.emit(Reset);
      this.onPushFilterTicket.emit(this.ticket);
    }
  }

  EmitFilterNumTickets() {
    if (this.date_debut_creation) {
      this.ticket.date_debut_creation = this.datePipe.transform(this.date_debut_creation, 'yyyy-MM-dd');
    } else {
      this.ticket.date_debut_creation = '';
    }

    if (this.date_fin_creation) {
      this.ticket.date_fin_creation = this.datePipe.transform(this.date_fin_creation, 'yyyy-MM-dd');
    } else {
      this.ticket.date_fin_creation = '';
    }
    if (this.date_debut_traitement) {
      this.ticket.date_debut_traitement = this.datePipe.transform(this.date_debut_traitement, 'yyyy-MM-dd');
    } else {
      this.ticket.date_debut_traitement = '';
    }
    if (this.date_fin_traitement) {
      this.ticket.date_fin_traitement = this.datePipe.transform(this.date_fin_traitement, 'yyyy-MM-dd');
    } else {
      this.ticket.date_fin_traitement = '';
    }
    if (this.date_debut_limit) {
      this.ticket.date_debut_limit = this.datePipe.transform(this.date_debut_limit, 'yyyy-MM-dd');
    } else {
      this.ticket.date_debut_limit = '';
    }
    if (this.date_fin_limit) {
      this.ticket.date_fin_limit = this.datePipe.transform(this.date_fin_limit, 'yyyy-MM-dd');
    } else {
      this.ticket.date_fin_limit = '';
    }
    this.ticket.recheche_status = [];
    if (this.selected2) this.ticket.recheche_status.push(this.selected2);
    // this.ticket.categorie = this.checkedNodeIds;
    this.onPushFilterTicket.emit(this.ticket);
  }

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    (node: TreeNode, level: number) => ({
      expandable: !!node.child && node.child.length > 0,
      libelle: node.libelle,
      id: node.id,
      level: level,
    }),
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.child
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  isLeaf(_: number, node: FlatNode) {
    return !node.expandable;
  }

  hasChild(_: number, node: FlatNode) {
    return node.expandable;
  }

  checkedNodeIds: string[] = [];
  isChecked(node: TreeNode): boolean {
    return this.checkedNodeIds.includes(node.id);

    // Replace 'id' with the actual property name holding the ID.
  }

  toggleNode(node: TreeNode): void {
    if (this.isChecked(node)) {
      this.checkedNodeIds = this.checkedNodeIds.filter((id) => id !== node.id); // Replace 'id' with the actual property name holding the ID.
    } else {
      this.checkedNodeIds.push(node.id); // Replace 'id' with the actual property name holding the ID.
    }
    this.ticket.categorie = this.checkedNodeIds;
    this.EmitFilterNumTickets();
  }
}
