
<div class="row containerTache" style="border-radius: 10px 10px 0px 0px;" [ngClass]="{'cover': !accees}">
    <div class="col-md-12 p-0" id="expand">
        <div class=" p-0   scrollbar "  >
        <div class="row d-flex align-items-center  mb-2">
            <div class=" col-md-4 card-doc-title ">
              <i class="fa-solid fa-list-check mr-2 fa-lg mt-2 " [ngClass]="{'ml-2': currentLanguage === 'ar'}"></i>
              <span> {{'languages.affaire.todoList' | translate}}</span>
            </div>
            <button type="button"  (click)="expandTask()" class="add-btn-en btn-details-add  col-md-4 " [ngClass]="{'btn-details-add-arabic': currentLanguage === 'ar'}" *ngIf="!isExpand" [disabled]="accees == false">
              <i class="fa fa-plus-circle fa-2x" aria-hidden="true"></i>
            </button>
            <label class=" col-md-4 m-0 ">
                <input type="search" class="search " [ngModel]="searchTaches" (input)="onInputChange($event.target.value)"  placeholder=" {{'languages.miniListing.search'| translate}}" >
            </label>
          </div>

          <div class="row d-flex justify-content-center">
            <p (click)="archiveFN(false)" class=" py-3 px-5" [ngClass]="{'p-notif-false': !archive, 'p-notif-true': archive}"> {{"languages.affaire.enCours" | translate}}</p> 
            <p (click)="archiveFN(true)" class="py-3 px-5" [ngClass]="{'p-notif-false': archive, 'p-notif-true': !archive}">{{"languages.affaire.Archive" | translate}} </p>
          </div>

          <div class="row pt-2" *ngIf="allTaches?.length ===0 && !loader">
                <div class="col-12  d-flex justify-content-center">
                  <img src="assets/icons/nodata.svg">

                </div>
                <div class="col-12  d-flex justify-content-center">
                  <p class="noData">{{"languages.ticket.noTasks" | translate}}</p>
                </div>

        </div>
        <div *ngIf="loader" class="col-12 d-flex justify-content-center text-secondary ">
          <div class="spinner">
              <mat-spinner [diameter]="20"></mat-spinner>
          </div>
      </div>

          <div  *ngIf="!loader" class="row  ">
            <div  class="example-list w-100 mw-100 overflow-hidden d-block mr-3  "   >

                <div class="example-box row px-2 py-2 flex-row justify-content-between align-items-center"  *ngFor="let tache of allTaches; let i = index" [ngStyle]="{ 'background-color': tache.statut==0 ? '#fff' : '#F0F0F0'}" >
                  <div class="col-4 d-flex align-items-center">
                    <button type="button" *ngIf="tache.statut==1" class="btnCheck statusbtnDone "  (click)="changerStatus(tache)"  [attr.data-title]="'languages.ticket.startTask' | translate">
                      <i class="fa-solid fa-check btnTerminer"></i>
                    </button>
                    <button type="button" *ngIf="tache.statut==0" class="btnCheck statusfermer  " (click)="changerStatus(tache)"  [attr.data-title]="'languages.ticket.endTask' | translate">
                      <i class="fa-solid fa-check"></i>

                    </button>
                    <span class="ml-4 taskName"  [ngClass]="{'tasksDone': tache.statut==1 }">{{ expandFileName(tache.titre) }}</span>
                  </div>
                  <div class="col-7">
                    <span *ngFor="let tagsAll of tagsList">
                        <span *ngFor="let tags of tache.tags" >
                            <span class="badge badge-primary  ml-2" [ngStyle]="{'color': tagsAll.couleur,'background-color': tagsAll.couleur_background}"  *ngIf="tagsAll.id==tags.id">{{tagsAll.libelle}}</span>
                        </span>
                    </span>
                    </div>
                    <div class="col-1">
                    <button  type="button" (click)="viewModif(tache)"   class=" float-right btn-dt-list edit ">
                      <img src="assets/icons/edit-icon.svg">
                    </button>
                    <div *ngIf=" tache.priorite == 3">
                        <div class="notification"></div>
                      <div class="bell-icon__circle"></div>
                      </div>
                  </div>

                </div>
            </div>
        </div>
          </div>

</div>
<app-task-detail class="col-md-4 p-0 " *ngIf="isExpand" [tagsList]="tagsList" [taskDetail]="taskDetail" [loaderAjout]="loaderAjout" [typeTache]="typeTache" [priorites]="priorites" [ListUser]="ListUser" [ListCLientProspect]="ListCLientProspect" (taskEmit)="addTodo($event)"  (inexpandEmit)="cancelAddOrUpdate($event )"></app-task-detail>

        </div>
        <div  *ngIf="totalPages> 0 && !loader" class="d-flex align-items-center justify-content-center containerTache" style="border-radius: 0px 0px 10px 10px;">
            <nav aria-label="  Page navigation example" >
                <ul class="pagination pg-blue">
                    <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                        <a class="page-link-symbol">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                        <a class="page-link-symbol-chevron" aria-label="Previous">
                          <i class="fa-solid fa-chevron-left fa-lg"></i>
                        </a>
                    </li>
                    <ng-container *ngFor="let item of [].constructor(3); let i = index">
                        <li *ngIf="i+page-3>0" class="page-item">
                            <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page -
                                3}}</a>
                        </li>
                    </ng-container>
                    <ng-container *ngFor="let item of [].constructor(3); let i = index">
                        <li *ngIf="i+page<totalPages" class="page-item">
                            <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                                [ngClass]="{'active-page':page==(i+page)}">
                                {{i + page}}
                            </a>
                        </li>
                    </ng-container>
                    <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                    <li class="page-item">
                        <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                            [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                    </li>
                    <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                        <a class="page-link-symbol-chevron" aria-label="Next">
                          <i class="fa-solid fa-chevron-right fa-lg"></i>
                        </a>
                    </li>
                    <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                        <a class="page-link-symbol">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
            </div>
