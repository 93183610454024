import { ChangeDetectorRef, Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
 
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-fiche-opportunite-prospect',
  templateUrl: './opportunite-prospect.component.html',
  styleUrls: ['./opportunite-prospect.component.css'],
})
export class OpportuniteProspectComponent implements OnInit, OnChanges {
   
  dataTable: any;
  listOpportunite: any[] = [];
  show_loading_add: boolean = false;
  @Input() prospect: string = '';
  page: number = 1;
  limit: number = 15;
  miniListing: any;
  currentLanguage: string;
  constructor(
    private apiProspectService: ApiProspectsService,
    private chRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.currentLanguage = this.translate.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.miniListing = object.miniListing;
        this.currentLanguage = this.translate.currentLang;
        this.getOpportunite(this.prospect);
      });
    });
    this.translate.get('languages').subscribe((object: any) => {
      this.miniListing = object.miniListing;
      this.currentLanguage = this.translate.currentLang;
      this.getOpportunite(this.prospect);
    });
  }

  getOpportunite(id) {
    this.show_loading_add = true;
    this.apiProspectService.getOpportuniteByProspec(id).subscribe(
      (data) => {
        this.listOpportunite = data;
        this.chRef.detectChanges();
        const table: any = $('#opp');
        this.dataTable = table.DataTable({
          destroy: true,
          stateSave: true,
          pagingType: 'full_numbers',
          language: {
            sProcessing: this.miniListing.traitement,
            searchPlaceholder: this.miniListing.search,
            sSearch: '<p aria-hidden="true"></p>',
            sLengthMenu: this.miniListing.Afficher + '_MENU_' + this.miniListing.element,
            sInfo:
              this.miniListing.displayingElement +
              '_START_' +
              this.miniListing.label_a +
              '_END_' +
              this.miniListing.label_sur +
              '_TOTAL_' +
              this.miniListing.element,
            sInfoEmpty: this.miniListing.sInfoEmpty,
            sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            sInfoPostFix: '',
            sLoadingRecords: this.miniListing.chargement,
            sZeroRecords: this.miniListing.noRecords,
            sEmptyTable: this.miniListing.emptyTable,
            oPaginate: {
              sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
              sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
              sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
            },
            oAria: {
              sSortAscending: this.miniListing.sSortAscending,
              sSortDescending: this.miniListing.sSortDescending,
            },
            select: {
              rows: {
                _: '%d' + this.miniListing.selectedLines,
                0: this.miniListing.zeroselected,
                1: this.miniListing.oneselected,
              },
            },
          },
        });
        this.dataTable.page.len(10).draw();
        this.show_loading_add = false;
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
