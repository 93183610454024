import { Component, OnInit } from '@angular/core'
import { AccessList } from './entity/accessList';
import { ApiAuthentificationService } from './services/ApiAuthentification/api-authentification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'geoprod-Front';
  accessListe : AccessList;
  currentLanguage: string;

  constructor(    
    private apiAuthentificationService: ApiAuthentificationService,
    private translate: TranslateService
    ){

  }

  ngOnInit(): void {
    const accessListObservable = this.apiAuthentificationService.GetAccessList();

    if (accessListObservable) {
      accessListObservable.subscribe((data) => {
        if (data) {
          this.accessListe = data;
          this.apiAuthentificationService.setAccessListe(this.accessListe);
        }
      });
    }
  
      this.translate.onLangChange.subscribe(() => {
        this.translate.get('languages').subscribe((object: any) => {
          this.currentLanguage = this.translate.currentLang;
      
        });
      });
  
      this.translate.get('languages').subscribe((object: any) => {
        this.currentLanguage = this.translate.currentLang;
      
      });
    }
  
  }

