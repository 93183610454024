<div class="flex-column bg-white rounded-card"  [ngClass]="{'rtl_class': currentLanguage === 'ar','notrtl_class': currentLanguage !== 'ar'}">
    <div class="p-3 mb-3">
        <table id="societyTable" class="table">
            <thead class="head-table">
                <tr>
                    <th> # </th>
                    <th>  {{ "languages.prospect.interlocuteur" | translate}}</th>
                    <th>  {{ "languages.prospect.RaisonSociale" | translate}}</th>
                    <th> {{ "languages.prospect.addedBy" | translate}}</th>
                    <th>   {{ "languages.prospect.poste" | translate}}</th>

                </tr>
            </thead>
            <tbody class="body-table">
                <tr *ngFor="let item of socueteListe; let index = index">
                    <td> {{index+1}}</td>
                    <td> {{item.interlocuteur}}</td>
                    <td> {{item.raison_sociale}} </td>
                    <td> {{item.mail_user}} </td>
                    <td> {{item.poste}} </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
