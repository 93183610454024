<div class="modal-content" [dir]="currentLanguage === 'ar' ? 'rtl' : 'ltr'">
    <div class="modal-header row">
    <div class="col-11">
        <h3 class="text-center title justify-content-center m-0 w-100">
            {{'languages.groupedactions.groupedactions' | translate}}
        </h3>
    </div>
        <div class="col-1">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    </div>
    <div class="row">
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <mat-form-field class="full-width m" appearance="standard">
                            <mat-label> {{'languages.opportunite.details.affecte' | translate}} </mat-label>
                            <input type="text" matInput [(ngModel)]="affecteUser" [formControl]="affecterAFormControl"
                                name="affecte"  [matAutocomplete]="auto" />
                            <mat-autocomplete autoActiveFirstOption [displayWith]="displayContactFn"
                                #auto="matAutocomplete">
                                <mat-option *ngFor="let option of listUserFilter | async" [value]="option">
                                    {{ option.nom + ' ' + option.prenom }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-6  w-100">
                    <mat-form-field class="w-100 mt-3 ">
                        <mat-label>{{'languages.opportunite.label_changestatus' | translate}}</mat-label>
                        <mat-select [(ngModel)]="selectedStatus">
                          <mat-option *ngFor="let status of statusList" value="{{status.id}}"> {{status.libelle.split('(')[0].trim()}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="row w-100 mt-2 mb-3 text-center d-flex justify-content-center">
        <ng-container *ngIf="saveLoader">
            <div>
                <div class="col-12 text-center">
                    <div class="customWidth">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                </div>
            </div>
        </ng-container> 
        </div>
        
    <div class="row w-100 mt-2 mb-4 text-center d-flex justify-content-center">
      
    
            <button type="button" class="btn-dt-save-popup mr-2 ml-2" (click)="Enregistrer()" [disabled]="saveLoader">
<i class="fa-solid fa-circle-check fa-icon-style fa-lg mr-2 ml-2"></i>
                {{'languages.buttons.apply' | translate}}
            </button>

            <button type="button" class="btn-dt-cancel-popup " aria-label="Close" (click)="activeModal.dismiss('')">
                <i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-2 ml-2"></i>
                {{'languages.buttons.annuler' | translate}}
            </button>
        
    </div>
</div>