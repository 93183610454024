import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';

import {
  ActionUser,
  FilterOpportunite,
  FiltreProspects,
  InformationsAncienContrat,
  RendezVous,
  Telephonie,
  Opportunite,
  Utilisateur,
} from '../../../../../entity/Opportunity/FilterOpportunite';
 
import { SaveFiltreComponent } from 'src/app/components/save-filtre/save-filtre.component';
import { FiltresUtilisateursComponent } from '../filtres-utilisateurs/filtres-utilisateurs.component';
import { FiltresOpportunitesComponent } from '../filtres-opportunites/filtres-opportunites.component';
import { FiltresProspectsComponent } from '../filtres-prospects/filtres-prospects.component';
import { FiltresStatusActuelComponent } from '../filtres-status-actuel/filtres-status-actuel.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { FilterHistoryService } from 'src/app/services/ApiFilterSave/filter-history.service';
import { TranslateService } from '@ngx-translate/core';
export interface CardItem {
  title: string;
  subTitle: string;
  subSubTitle: string;
  checked: boolean;
}
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit ,OnChanges{
   

  filterOpportunite: FilterOpportunite = new FilterOpportunite();
  panelOpenState = false;
  collapseFilterBox: boolean = true;

  @Output() onPushFilters: EventEmitter<FilterOpportunite> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();
  @Input() onRefreshFilter: any;
  @Input() filterSsearch;
  onResetOpp: boolean = false;
  onResetPros: boolean = false;
  onResetUtil: boolean = false;
  onResetAncienContrat: boolean = false;
  onResetTelephonie: boolean = false;
  onResetActionsUtil: boolean = false;
  onResetStatut: boolean = false;
  detect: boolean = false;
  filterParamString: string = null;
  collectionParamString: string = null;
  
  @ViewChild(FiltresUtilisateursComponent) FiltresUtilisateursComponent: FiltresUtilisateursComponent;
  @ViewChild(FiltresOpportunitesComponent) FiltresOpportunitesComponent: FiltresOpportunitesComponent;
  @ViewChild(FiltresProspectsComponent) FiltresProspectsComponent: FiltresProspectsComponent;
  @ViewChild(FiltresStatusActuelComponent) FiltresStatusActuelComponent: FiltresStatusActuelComponent;
  @Output() collapseCloseToparent: EventEmitter<boolean> = new EventEmitter();
  @Input()collapseFromParent : boolean = false;
  alerts: any;
  buttons: any;
  currentLanguage: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private apiFIlterHistoryService: FilterHistoryService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.currentLanguage = this.translate.currentLang;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.currentLanguage = this.translate.currentLang;
      });});
    if (this.onRefreshFilter != null) {
      this.filterOpportunite = JSON.parse(this.onRefreshFilter);
    }
    this.route.queryParamMap.subscribe((queryParams) => {
      this.filterParamString = queryParams.get('filter');
      this.collectionParamString = queryParams.get('collection');
      if (this.filterParamString !== null && this.collectionParamString !== null) {
        if (!this.filterParamString || !this.collectionParamString) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = this.alerts.invalidNumberFilter;
          this.notificationsService.sendMessage(notificationMessage);
        } else {
          this.getFilterByID(this.filterParamString, this.collectionParamString);
          this.collapseFilterBox = true;
        }
        this.collapseFilterBox = false;
      }
    });
  }

  getFilterByID(IDFilter, IDCollection) {
    const unsubscribeFilterSave = this.apiFIlterHistoryService.getFiltrebyID(IDFilter, IDCollection).subscribe(
      (response) => {
        this.getFilterFromCollection(response.input);
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error.error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  toggleFilterCorp() {
    this.collapseFilterBox = !this.collapseFilterBox;
    this.collapseCloseToparent.emit(false);
  }

  closefilter(event) {
    this.collapseFilterBox = !this.collapseFilterBox;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterSsearch) {
      if (this.filterSsearch === true) {
        this.onResetOpp = true;
        this.onResetPros = true;
        this.onResetUtil = true;
        this.onResetAncienContrat = true;
        this.onResetTelephonie = true;
        this.onResetActionsUtil = true;
        this.onResetStatut = true;
        this.cdRef.detectChanges();
      }
    }
    if(this.collapseFromParent){
      this.collapseFilterBox = true;
    }
  }

  resetForm() {
    this.router.navigate([], {
      queryParams: {
        filter: null,
        collection: null,
      },
      queryParamsHandling: 'merge',
    });
    localStorage.removeItem('savedFilter');
    localStorage.removeItem('savedCollection');
    this.onResetOpp = true;
    this.onResetPros = true;
    this.onResetUtil = true;
    this.onResetAncienContrat = true;
    this.onResetTelephonie = true;
    this.onResetActionsUtil = true;
    this.onResetStatut = true;
    this.cdRef.detectChanges();
    this.toggleFilterCorp();

    this.onSubmitFilter.emit('reset');
   
  }

  getResetOpp($event) {
    this.onResetOpp = $event;
  }

  getResetPros($event) {
    this.onResetPros = $event;
  }

  getResetUtil($event) {
    this.onResetUtil = $event;
  }

  getResetAncienContrat($event) {
    this.onResetAncienContrat = $event;
  }

  getResetTelephonie($event) {
    this.onResetTelephonie = $event;
  }

  getResetActionsUtil($event) {
    this.onResetActionsUtil = $event;
  }

  getResetStatut($event) {
    this.onResetStatut = $event;
  }

  EmitFilters() {
    this.onPushFilters.emit(this.filterOpportunite);
  }

  EmitFilterOpportunite($event: Opportunite) {
    this.filterOpportunite.filtre_opp = $event;
    this.detect = true;
    this.EmitFilters();
  }

  EmitFilterUtilisateur($event: Utilisateur) {
    this.filterOpportunite.utilisateur = $event;
    this.EmitFilters();
  }

  EmitFilterProspec($event: FiltreProspects) {
    this.filterOpportunite.prospect = $event;
    this.EmitFilters();
  }

  EmitFilterAncienContart($event: InformationsAncienContrat) {
    this.filterOpportunite.info_contrat = $event;
    this.EmitFilters();
  }

  EmitFilterTelephonie($event: Telephonie) {
    this.filterOpportunite.telephonie = $event;
    this.EmitFilters();
  }

  EmitFilterActionsUtilisateurs($event: ActionUser) {
    this.filterOpportunite.action_user = $event;
    this.EmitFilters();
  }

  EmitFilterStatusActuel($event: RendezVous) {
    this.filterOpportunite.rdv = $event;
    this.EmitFilters();
  }

  submitFilter() {
    if (this.detect) {
      this.onSubmitFilter.emit('opportunite');
      this.detect = false;
    } else {
      this.onSubmitFilter.emit('submit');
    }

    this.toggleFilterCorp();
  }

  getFilterFromCollection($event) {
    localStorage.setItem('filtreOpportunite', JSON.stringify($event));
    this.FiltresUtilisateursComponent.ngOnInit();
    this.FiltresOpportunitesComponent.ngOnInit();
    this.FiltresProspectsComponent.ngOnInit();
    this.FiltresStatusActuelComponent.ngOnInit();
    this.onPushFilters.emit($event);
    this.submitFilter();
  }

  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
      this.onSubmitFilter.emit('');
      this.toggleFilterCorp();
    }
  }
}
